import React from "react";
import "./index.css";

const Footer = (props) => {
  return (
    <footer className="df fdc">
      <div className="topFooter">
        <div className="df fdc alic jcc">
          <img
            onClick={() => {
              window.location.href = "#";
            }}
            src={require("../../assets/logo.png")}
            alt="logo"
            className="headerImage cp"
          />
        </div>
        <div className="footerlinks">
          <div className="df fdr alic">
            <img
              src={require("../../assets/img/pin.png")}
              alt="pin"
              className="footerIcon"
            />
            <p className="ml-10 colorDark">
              A1/G02, Tulip White Sector 69, Gurgaon 122101, Haryana, INDIA.
            </p>
          </div>
          <div className="footerDetails">
            <div className="footerItems">
              <img
                src={require("../../assets/img/phone.png")}
                alt="phone"
                className="footerIcon"
              />
              <p className="colorDark ml-10">(+91) 8700977671</p>
            </div>
            <div className="footerItem-2">
              <img
                src={require("../../assets/img/print.png")}
                alt="print"
                className="footerIcon"
              />
              <p className="colorDark ml-10">info@resixty.com</p>
            </div>
          </div>
          <div className="w-70 df fdr alic jcsa mt-10">
            <p className="colorGreyText">Social Media</p>
            <img
              src={require("../../assets/img/Facebook.png")}
              alt="Facebook"
              className="socialIcons"
            />
            <img
              src={require("../../assets/img/Instagram.png")}
              alt="Instagram"
              className="socialIcons"
            />
            <img
              src={require("../../assets/img/Twitter.png")}
              alt="Twitter"
              className="socialIcons"
            />
            <img
              src={require("../../assets/img/LinkedIn.png")}
              alt="LinkedIn"
              className="socialIcons"
            />
          </div>
        </div>
      </div>
      <div className="bottomFooter">
        <div className="bottomFooterLinks">
          <a href="https://www.resixty.com/about.html">
            <span className="ml-20 mediumFont">ABOUT US</span>
          </a>
          <a href="https://www.resixty.com/index.html#contact">
            <span className="ml-20 mediumFont">CONTACT US</span>
          </a>
          <a href="https://www.resixty.com/privacy.html">
            <span className="ml-20 mediumFont">PRIVACY POLICY</span>
          </a>
          <a href="https://www.resixty.com/termsofuse.html">
            <span className="ml-20 mediumFont">TERMS OF USE</span>
          </a>
          <a href="https://www.resixty.com/refundpolicy.html">
            <span className="ml-20 mediumFont">REFUND POLICY</span>
          </a>
          <div>
            <p className="colorGreyText">
              Copyright © 2024 RESIXTY MARKETPLACE PRIVATE LIMITED | All Rights
              Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
