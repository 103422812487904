import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { changePassword } from "../../services/auth";
import { useNavigate, useLocation } from "react-router-dom";

const ChangePassword = (props) => {
  const navigate = useNavigate();
  const paramsData = window.location.href
    ?.split("?")?.[1]
    ?.split("activationLink=")?.[1];
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onNext = async (e) => {
    try {
      e.preventDefault();
      let obj = {
        password,
        unique_key: paramsData,
        change_password: true,
      };
      setLoading(true);
      const { data } = await changePassword(obj);
      if (data?.success) {
        setLoading(false);
        alert(data.message);
        navigate("/");
      } else {
        alert(data?.message);
        setLoading(false);
      }
      return;
    } catch (e) {
      alert(e?.response?.data?.message);
      setLoading(false);
      return;
    }
  };

  return (
    <div className="container">
      <div className="card">
        <div className="fieldsContainer">
          <div className="df fdc alic">
            <img
              src={require("../../assets/logo.png")}
              alt="logo"
              className="formLogo"
            />
            <h6 className="mt-10 colorGrexTextColor boldFont">
              Change Password
            </h6>
            <h6
              className="cp tdul colorGrexTextColor mediumFont"
              onClick={() => navigate("/")}
            >
              Already have an account? Login
            </h6>
          </div>
          <form onSubmit={onNext} className={"df fdc mt-10"}>
            <div className="fieldItem">
              <label className="mt-10 colorGrexTextColor " htmlFor="password">
                Password
              </label>
              <input
                className="mt-5"
                value={password}
                required
                onChange={(e) => setPassword(e?.target?.value)}
                type="password"
                id="password"
                minLength={8}
                name="password"
                placeholder="*********"
              />
            </div>
            <div className="fieldItem">
              <label
                className="mt-10 colorGrexTextColor"
                htmlFor="confirmPassword"
              >
                Confirm Password
              </label>
              <input
                className="mt-5"
                value={confirmPassword}
                required
                onChange={(e) => {
                  if (password !== e?.target?.value) {
                    e?.target?.setCustomValidity("Password do not match!");
                  } else {
                    e?.target?.setCustomValidity("");
                  }
                  setConfirmPassword(e?.target?.value);
                }}
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                minLength={8}
                placeholder="*********"
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="customCta authBtn"
            >
              <span className="mediumFont colorDark">Change Password</span>
              {loading ? (
                <i
                  className="fa fa-spinner fa-spin colorWhite ml-10"
                  style={{ fontSize: "16px" }}
                ></i>
              ) : null}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
