import { Provider } from "react-redux";
import store from "./redux/store";
import { useEffect, useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Onboarding from "./containers/Onboarding";
import SupplierOnboarding from "./containers/SupplierOnboarding";
import SupplierDashboard from "./containers/SupplierDashboard";
import ChangePassword from "./containers/ChangePassword";
import Details from "./containers/Details";
import Output from "./containers/Output";
import {
  Route,
  Routes,
  createHashRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { CONFIG } from "./generic/config";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Auth from "./containers/Auth";
// import { GoogleOAuthProvider } from "@react-oauth/google";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAgglQbbi6eLqi0l_qCCa0lkoXK9s2VqhU",
  authDomain: "resixty.firebaseapp.com",
  projectId: "resixty",
  storageBucket: "resixty.appspot.com",
  messagingSenderId: "341587495407",
  appId: "1:341587495407:web:9f1fc44225126567f1ce1a",
  measurementId: "G-K7LB6ZNKZK",
};

// Initialize Firebase

const App = () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const [authData, setAuthData] = useState(
    localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth"))
      : false
  );
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isLoggedIn")
      ? JSON.parse(localStorage.getItem("isLoggedIn"))
      : false
  );
  const [token, setToken] = useState(
    localStorage.getItem("token") ? localStorage.getItem("token") : null
  );
  const [supplierDetails, setSupplierDetails] = useState({
    companyName: "",
    companyAddress: "",
    gstin: "",
    pan: "",
    cancelledCheque: "",
    installationPhotos: [],
    digitalSignature: "",
    catalogue: "",
    referenceList: [],
    kwInstalled: "",
    top3Projects: "",
    customerFeedback: "",
    companyPhoto: "",
    personPhoto: "",
  });
  const [userDetails, setUserDetails] = useState({
    industryType: "",
    name: "",
    gstin: "",
    roofTopType: "",
    customerAddress: "",
    tilt: "",
    bill: "",
    load: "",
    workingDaysInWeek: "",
    fromTime: "",
    toTime: "",
    averageMonthlyBill: "",
    connectedLoad: "",
    perUnitCost: "",
  });
  const [area, setArea] = useState({
    area: 0,
    latLng: [],
    azimuth: 0,
    module_type: 0,
    losses: CONFIG.losses,
    array_type: 0,
    tilt: 0,
    system_capacity: 0,
    panels: 0,
    albedo: CONFIG.albedo,
    soiling: CONFIG.soiling,
    inv_eff: CONFIG.inv_eff,
  });
  const [latLong, setLatLong] = useState({
    lat: 26.2131187,
    lng: 78.17722739999999,
  });
  const [address, setAddress] = useState("");

  useEffect(() => {
    localStorage.setItem("isLoggedIn", JSON.stringify(isAuthenticated));
    localStorage.setItem("auth", JSON.stringify(authData));
    localStorage.setItem("token", token);
  }, [isAuthenticated, token, authData]);

  const onChangeUserDetails = (e) => {
    let previousData = { ...userDetails };
    setUserDetails({
      ...previousData,
      [`${e.target.name}`]: e.target.value,
    });
  };

  const onChangeSupplierDetails = (e) => {
    let previousData = { ...supplierDetails };
    setSupplierDetails({
      ...previousData,
      [`${e.target.name}`]:
        e.target.type == "file" ? e.target.files : e.target.value,
    });
  };

  const router = createHashRouter(
    createRoutesFromElements(
      <>
        {isAuthenticated ? (
          <>
            <Route
              exact
              path="/onboarding"
              element={
                <Onboarding
                  {...userDetails}
                  setArea={setArea}
                  setLatLong={setLatLong}
                  setAddress={setAddress}
                  onChangeUserDetails={onChangeUserDetails}
                  area={area}
                  latLong={latLong}
                  address={address}
                  authData={authData}
                />
              }
            />
            <Route
              exact
              path="/supplier-onboarding"
              element={
                <SupplierOnboarding
                  {...supplierDetails}
                  onChangeSupplierDetails={onChangeSupplierDetails}
                  authData={authData}
                />
              }
            />

            <Route
              exact
              path="/supplier-dashboard"
              element={<SupplierDashboard authData={authData} />}
            />
            <Route
              exact
              path="/details"
              element={
                <Details
                  authData={authData}
                  {...userDetails}
                  onChangeUserDetails={onChangeUserDetails}
                />
              }
            />
            <Route
              exact
              path="/output"
              element={<Output authData={authData} />}
            />
            <Route exact path="/" element={<Navigate to="/onboarding" />} />
          </>
        ) : (
          <>
            <Route
              exact
              path="/"
              element={
                <Auth
                  setIsAuthenticated={setIsAuthenticated}
                  setToken={setToken}
                  setAuthData={setAuthData}
                />
              }
            />
            <Route
              exact
              path="/changePassword"
              element={
                <ChangePassword
                  setIsAuthenticated={setIsAuthenticated}
                  setToken={setToken}
                  setAuthData={setAuthData}
                />
              }
            />
            <Route
              exact
              path="/supplier-onboarding"
              element={<Navigate to="/" />}
            />
            <Route
              exact
              path="/supplier-dashboard"
              element={<Navigate to="/" />}
            />
            <Route exact path="/onboarding" element={<Navigate to="/" />} />
            <Route exact path="/output" element={<Navigate to="/" />} />
            <Route exact path="/details" element={<Navigate to="/" />} />
          </>
        )}
      </>
    )
  );

  return (
    // <GoogleOAuthProvider clientId="1019125706434-8u68mfsin092uq9uj805bmdbfn7theul.apps.googleusercontent.com">
    <Provider store={store}>
      <div className="App">
        <Header
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
          setToken={setToken}
          setAuthData={setAuthData}
        />
        <RouterProvider router={router} />
        <Footer />
      </div>
    </Provider>
    // </GoogleOAuthProvider>
  );
};

export default App;
