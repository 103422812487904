export const PAYMENT_MODES = {
  LOAN: "LOAN",
  UPFRONT: "UPFRONT",
  RESCO: "RESCO",
};

export const INDUSTRY_TYPES = {
  EDUCATIONAL_INSTITUTE: "EDUCATIONAL_INSTITUTE",
  HOSPITAL: "HOSPITAL",
  INDUSTRIAL: "INDUSTRIAL",
  COMMERCIAL: "COMMERCIAL",
  OTHERS: "OTHERS",
};

export const LOAN = {
  data: {
    cashflow: {
      0: {
        loanRepaymentPrinciple: -20,
        loanRepaymentInterest: -4.6198375924615,
        loanRepayment: -24.6198375924615,
        operationalExpenses: -0.6,
        ownershipCost: -51.219837592461495,
        electricityCost: 7.1,
        solarPlantDegradation: 0.99,
        beforeSolarBill: -2.4,
        generationFromSolar: 4.01563234470538,
        netFeedInTariffDiscom: 7.1,
        afterSolarBill: 26.110989647408196,
        billSaving: 28.510989647408195,
        incomeTaxBenefitLoan: 1.154959398115375,
        depreciationBenefit: 59.63967518492299,
        incomeTaxBenefitDepreciation: 14.909918796230748,
        additionalTaxBenefit: 16.064878194346125,
        cumulativeSavingFromSolar: -6.643969750707176,
        netSavingFromSolar: -6.643969750707176,
        discountRate: 1,
        npv: -6.643969750707176,
        co2Intensity: 626.5915789473685,
        co2Mitigation: 251.61614113410673,
        co2ExchangePrices: 1.5,
        co2ExchangePricesAmount: 377.42421170116006,
        co2ExchangeNpv: 377.42421170116006,
      },
      1: {
        loanRepaymentPrinciple: -20,
        loanRepaymentInterest: -4.6198375924615,
        loanRepayment: -24.6198375924615,
        operationalExpenses: -0.6,
        ownershipCost: -25.2198375924615,
        electricityCost: 7.526,
        solarPlantDegradation: 0.986,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.999407567555055,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.555541353419343,
        billSaving: 30.099541353419344,
        incomeTaxBenefitLoan: 1.154959398115375,
        depreciationBenefit: 17.891902555476896,
        incomeTaxBenefitDepreciation: 4.472975638869224,
        additionalTaxBenefit: 5.627935036984598,
        cumulativeSavingFromSolar: 3.8636690472352675,
        netSavingFromSolar: 10.507638797942443,
        discountRate: 0.8474576271186441,
        npv: 8.904778642324105,
        co2Intensity: 597.1221052631579,
        co2Mitigation: 238.813466654388,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 3248.858202610737,
        co2ExchangeNpv: 2753.2696632294383,
      },
      2: {
        loanRepaymentPrinciple: -20,
        loanRepaymentInterest: -4.6198375924615,
        loanRepayment: -24.6198375924615,
        operationalExpenses: -0.6,
        ownershipCost: -25.2198375924615,
        electricityCost: 7.526,
        solarPlantDegradation: 0.982,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.9831827904047303,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.433433680586,
        billSaving: 29.977433680586,
        incomeTaxBenefitLoan: 1.154959398115375,
        depreciationBenefit: 14.313522044381514,
        incomeTaxBenefitDepreciation: 3.5783805110953786,
        additionalTaxBenefit: 4.733339909210754,
        cumulativeSavingFromSolar: 13.35460504457052,
        netSavingFromSolar: 9.490935997335251,
        discountRate: 0.7181844297615628,
        npv: 6.816242457149707,
        co2Intensity: 567.6526315789473,
        co2Mitigation: 226.10641930332199,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 3075.9894126056092,
        co2ExchangeNpv: 2209.127702244764,
      },
      3: {
        loanRepaymentPrinciple: -20,
        loanRepaymentInterest: -4.6198375924615,
        loanRepayment: -24.6198375924615,
        operationalExpenses: -0.6,
        ownershipCost: -25.2198375924615,
        electricityCost: 7.526,
        solarPlantDegradation: 0.978,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.966958013254405,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.311326007752655,
        billSaving: 29.855326007752655,
        incomeTaxBenefitLoan: 1.154959398115375,
        depreciationBenefit: 11.450817635505212,
        incomeTaxBenefitDepreciation: 2.862704408876303,
        additionalTaxBenefit: 4.017663806991678,
        cumulativeSavingFromSolar: 22.00775726685335,
        netSavingFromSolar: 8.65315222228283,
        discountRate: 0.6086308726792905,
        npv: 5.266575588474741,
        co2Intensity: 538.1831578947367,
        co2Mitigation: 213.49499908090866,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2904.421549996528,
        co2ExchangeNpv: 1767.7206226029243,
      },
      4: {
        loanRepaymentPrinciple: -20,
        loanRepaymentInterest: -4.6198375924615,
        loanRepayment: -24.6198375924615,
        operationalExpenses: -0.6,
        ownershipCost: -25.2198375924615,
        electricityCost: 7.526,
        solarPlantDegradation: 0.974,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.950733236104081,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.18921833491931,
        billSaving: 29.73321833491931,
        incomeTaxBenefitLoan: 1.154959398115375,
        depreciationBenefit: 9.160654108404168,
        incomeTaxBenefitDepreciation: 2.290163527101042,
        additionalTaxBenefit: 3.4451229252164177,
        cumulativeSavingFromSolar: 29.96626093452758,
        netSavingFromSolar: 7.958503667674227,
        discountRate: 0.5157888751519412,
        npv: 4.104907654642288,
        co2Intensity: 508.71368421052625,
        co2Mitigation: 200.97920598714816,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2734.1546147834947,
        co2ExchangeNpv: 1410.2465332506677,
      },
      5: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.526,
        solarPlantDegradation: 0.97,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.934508458953756,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.067110662085973,
        billSaving: 29.611110662085974,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 7.328523286723336,
        incomeTaxBenefitDepreciation: 1.832130821680834,
        additionalTaxBenefit: 1.832130821680834,
        cumulativeSavingFromSolar: 60.809502418294386,
        netSavingFromSolar: 30.843241483766807,
        discountRate: 0.4371092162304586,
        npv: 13.481865110976077,
        co2Intensity: 479.2442105263157,
        co2Mitigation: 188.55904002204036,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2565.1886069665075,
        co2ExchangeNpv: 1121.267581474432,
      },
      6: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.966,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.9182836818034312,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.561703168607785,
        billSaving: 31.258343168607784,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 5.8628186293786655,
        incomeTaxBenefitDepreciation: 1.4657046573446664,
        additionalTaxBenefit: 1.4657046573446664,
        cumulativeSavingFromSolar: 92.93355024424685,
        netSavingFromSolar: 32.124047825952445,
        discountRate: 0.3704315391783548,
        npv: 11.899760480806647,
        co2Intensity: 449.7747368421052,
        co2Mitigation: 176.23450118558534,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2397.523526545567,
        co2ExchangeNpv: 888.1183301545916,
      },
      7: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.962,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.9020589046531065,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.43226903540444,
        billSaving: 31.128909035404437,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 4.690254903502932,
        incomeTaxBenefitDepreciation: 1.172563725875733,
        additionalTaxBenefit: 1.172563725875733,
        cumulativeSavingFromSolar: 124.635023005527,
        netSavingFromSolar: 31.701472761280172,
        discountRate: 0.31392503320199566,
        npv: 9.951885889137039,
        co2Intensity: 420.30526315789473,
        co2Mitigation: 164.00558947778305,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2231.1593735206734,
        co2ExchangeNpv: 700.4167804114212,
      },
      8: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.958,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.8858341275027812,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.302834902201088,
        billSaving: 30.999474902201086,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 3.7522039228023463,
        incomeTaxBenefitDepreciation: 0.9380509807005866,
        additionalTaxBenefit: 0.9380509807005866,
        cumulativeSavingFromSolar: 155.9725488884287,
        netSavingFromSolar: 31.337525882901677,
        discountRate: 0.26603816373050476,
        npv: 8.336977841744327,
        co2Intensity: 390.8357894736842,
        co2Mitigation: 151.87230489863344,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2066.0961478918257,
        co2ExchangeNpv: 549.6604252758107,
      },
      9: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.954,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.8696093503524565,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.173400768997745,
        billSaving: 30.870040768997743,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 3.0017631382418757,
        incomeTaxBenefitDepreciation: 0.7504407845604689,
        additionalTaxBenefit: 0.7504407845604689,
        cumulativeSavingFromSolar: 186.9930304419869,
        netSavingFromSolar: 31.020481553558213,
        discountRate: 0.2254560709580549,
        npv: 6.993755890292054,
        co2Intensity: 361.36631578947373,
        co2Mitigation: 139.83464744813662,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1902.3338496590252,
        co2ExchangeNpv: 428.89271539463493,
      },
      10: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.95,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.853384573202132,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.0439666357944,
        billSaving: 30.7406066357944,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 2.4014105105935073,
        incomeTaxBenefitDepreciation: 0.6003526276483768,
        additionalTaxBenefit: 0.6003526276483768,
        cumulativeSavingFromSolar: 217.73398970542968,
        netSavingFromSolar: 30.740959263442775,
        discountRate: 0.19106446691360587,
        npv: 5.873504994082568,
        co2Intensity: 331.8968421052632,
        co2Mitigation: 127.89261712629252,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1739.8724788222712,
        co2ExchangeNpv: 332.4278076638313,
      },
      11: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.946,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.8371597960518073,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.589404452746525,
        billSaving: 32.447842852746525,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 1.921128408474806,
        incomeTaxBenefitDepreciation: 0.4802821021187015,
        additionalTaxBenefit: 0.4802821021187015,
        cumulativeSavingFromSolar: 250.0621146602949,
        netSavingFromSolar: 32.328124954865224,
        discountRate: 0.1619190397572931,
        npv: 5.234538949845562,
        co2Intensity: 302.4273684210527,
        co2Mitigation: 116.04621393310113,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1578.7120353815633,
        co2ExchangeNpv: 255.62353682226447,
      },
      12: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.942,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.8209350189014826,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.452204271550983,
        billSaving: 32.31064267155098,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 1.5369027267798403,
        incomeTaxBenefitDepreciation: 0.3842256816949601,
        additionalTaxBenefit: 0.3842256816949601,
        cumulativeSavingFromSolar: 282.15698301354087,
        netSavingFromSolar: 32.094868353245936,
        discountRate: 0.137219525218045,
        npv: 4.404042597368066,
        co2Intensity: 272.9578947368422,
        co2Mitigation: 104.29543786856253,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1418.8525193369028,
        co2ExchangeNpv: 194.69426905783683,
      },
      13: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.938,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.8047102417511574,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.315004090355433,
        billSaving: 32.173442490355434,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 1.2295221814238744,
        incomeTaxBenefitDepreciation: 0.3073805453559686,
        additionalTaxBenefit: 0.3073805453559686,
        cumulativeSavingFromSolar: 314.03780604925225,
        netSavingFromSolar: 31.880823035711405,
        discountRate: 0.1162877332356314,
        npv: 3.70734864450918,
        co2Intensity: 243.48842105263165,
        co2Mitigation: 92.64028893267658,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1260.2939306882877,
        co2ExchangeNpv: 146.55672441036492,
      },
      14: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.9339999999999999,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.7884854646008326,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.177803909159884,
        billSaving: 32.03624230915989,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.9836177451390995,
        incomeTaxBenefitDepreciation: 0.24590443628477487,
        additionalTaxBenefit: 0.24590443628477487,
        cumulativeSavingFromSolar: 345.71995279469695,
        netSavingFromSolar: 31.682146745444665,
        discountRate: 0.09854892647087406,
        npv: 3.1222415500562684,
        co2Intensity: 214.01894736842112,
        co2Mitigation: 81.0807671254434,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1103.0362694357195,
        co2ExchangeNpv: 108.70304021132795,
      },
      15: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.9299999999999999,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.7722606874505082,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.040603727964342,
        billSaving: 31.899042127964343,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.786894196111274,
        incomeTaxBenefitDepreciation: 0.1967235490278185,
        additionalTaxBenefit: 0.1967235490278185,
        cumulativeSavingFromSolar: 377.2157184716892,
        netSavingFromSolar: 31.49576567699216,
        discountRate: 0.08351603938209666,
        npv: 2.6304016066489657,
        co2Intensity: 184.5494736842106,
        co2Mitigation: 69.61687244686298,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 947.0795355791985,
        co2ExchangeNpv: 79.09633179141015,
      },
      16: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9259999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7560359103001835,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.637607759574923,
        billSaving: 33.66755246357492,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.6295153568890215,
        incomeTaxBenefitDepreciation: 0.15737883922225537,
        additionalTaxBenefit: 0.15737883922225537,
        cumulativeSavingFromSolar: 410.4406497744863,
        netSavingFromSolar: 33.22493130279718,
        discountRate: 0.07077630456109887,
        npv: 2.3515378569083607,
        co2Intensity: 155.08,
        co2Mitigation: 58.24860489693525,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 792.4237291187233,
        co2ExchangeNpv: 56.08482319354847,
      },
      17: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9219999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7398111331498587,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.492175567507648,
        billSaving: 33.522120271507646,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.5036122855112183,
        incomeTaxBenefitDepreciation: 0.12590307137780457,
        additionalTaxBenefit: 0.12590307137780457,
        cumulativeSavingFromSolar: 443.4886731173717,
        netSavingFromSolar: 33.04802334288545,
        discountRate: 0.059979919119575315,
        npv: 1.9822177671681063,
        co2Intensity: 125.61052631578947,
        co2Mitigation: 46.97596447566028,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 639.068850054295,
        co2ExchangeNpv: 38.33129793809662,
      },
      18: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9179999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7235863559995335,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.34674337544037,
        billSaving: 33.37668807944037,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.40288982840897347,
        incomeTaxBenefitDepreciation: 0.10072245710224337,
        additionalTaxBenefit: 0.10072245710224337,
        cumulativeSavingFromSolar: 476.36608365391436,
        netSavingFromSolar: 32.87741053654261,
        discountRate: 0.050830439931843496,
        npv: 1.6711732413922877,
        co2Intensity: 96.14105263157893,
        co2Mitigation: 35.798951183038035,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 487.0148983859132,
        co2ExchangeNpv: 24.755181538318027,
      },
      19: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9139999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7073615788492087,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.201311183373086,
        billSaving: 33.23125588737309,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.3223118627271788,
        incomeTaxBenefitDepreciation: 0.0805779656817947,
        additionalTaxBenefit: 0.0805779656817947,
        cumulativeSavingFromSolar: 509.07791750696924,
        netSavingFromSolar: 32.711833853054884,
        discountRate: 0.04307664401003686,
        npv: 1.4091160218035175,
        co2Intensity: 66.6715789473684,
        co2Mitigation: 24.717565019068537,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 336.2618741135782,
        co2ExchangeNpv: 14.485033045338435,
      },
      20: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9099999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.6911368016988844,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.055878991305814,
        billSaving: 33.08582369530581,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.25784949018174075,
        incomeTaxBenefitDepreciation: 0.06446237254543519,
        additionalTaxBenefit: 0.06446237254543519,
        cumulativeSavingFromSolar: 541.6282035748205,
        netSavingFromSolar: 32.55028606785125,
        discountRate: 0.03650563051698039,
        npv: 1.1882687164149925,
        co2Intensity: 37.20210526315787,
        co2Mitigation: 13.731805983751778,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 186.8097772372898,
        co2ExchangeNpv: 6.819608704783916,
      },
      21: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.9059999999999999,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6749120245485596,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.705073607192855,
        billSaving: 34.91681499343286,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.20627959214538694,
        incomeTaxBenefitDepreciation: 0.051569898036346734,
        additionalTaxBenefit: 0.051569898036346734,
        cumulativeSavingFromSolar: 575.9965884662897,
        netSavingFromSolar: 34.368384891469205,
        discountRate: 0.03093697501439016,
        npv: 1.063253864672327,
        co2Intensity: 7.732631578947345,
        co2Mitigation: 2.8416740770877515,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 38.65860775704795,
        co2ExchangeNpv: 1.195980382270902,
      },
      22: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.9019999999999999,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6586872473982344,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.550915483601536,
        billSaving: 34.762656869841535,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.1650236737163141,
        incomeTaxBenefitDepreciation: 0.041255918429078525,
        additionalTaxBenefit: 0.041255918429078525,
        cumulativeSavingFromSolar: 610.2005012545603,
        netSavingFromSolar: 34.203912788270614,
        discountRate: 0.02621777543592387,
        npv: 0.8967505045128036,
        co2Intensity: 0,
        co2Mitigation: 0,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 0,
        co2ExchangeNpv: 0,
      },
      23: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.8979999999999999,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6424624702479096,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.39675736001022,
        billSaving: 34.60849874625022,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.13201893897305014,
        incomeTaxBenefitDepreciation: 0.033004734743262536,
        additionalTaxBenefit: 0.033004734743262536,
        cumulativeSavingFromSolar: 644.2420047355538,
        netSavingFromSolar: 34.041503480993484,
        discountRate: 0.022218453759257517,
        npv: 0.7563495709880576,
        co2Intensity: 0,
        co2Mitigation: 0,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 0,
        co2ExchangeNpv: 0,
      },
      24: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.894,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6262376930975857,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.242599236418908,
        billSaving: 34.45434062265891,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.10561515117843784,
        incomeTaxBenefitDepreciation: 0.02640378779460946,
        additionalTaxBenefit: 0.02640378779460946,
        cumulativeSavingFromSolar: 678.1227491460072,
        netSavingFromSolar: 33.880744410453524,
        discountRate: 0.018829198101065692,
        npv: 0.6379472483160036,
        co2Intensity: 0,
        co2Mitigation: 0,
        co2ExchangePrices: 292,
        co2ExchangePricesAmount: 0,
        co2ExchangeNpv: 0,
      },
    },
    paybackPeriod: 20,
    emi: 205165.3132705125,
    totalInterestPaid: 2309918.79623075,
    actualRate: 4.6198376124615,
    processingFee: 0.01,
    loaAmount: 10000000,
    consumptionToGenerationRation: 0.7790512890552613,
    netMetering: 0.22094871094473867,
    connectedLoadUtilizationRatio: 0.31303082191780823,
    consumptionSum: 2262.5,
    consumption: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 57.46919648767127,
      8: 97.96806917260278,
      9: 127.45948443013698,
      10: 143.55190790410958,
      11: 151.15583146301384,
      12: 100,
      13: 135.3853433150685,
      14: 107.02266055890422,
      15: 68.41050304109586,
      16: 26.46102724931508,
      17: 3.7613237780821898,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    exported: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 1.35824637260274,
      6: 16.82772386027397,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 48.61092561917812,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    imported: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 255.03080351232873,
      8: 102.03193082739722,
      9: 72.54051556986302,
      10: 56.448092095890416,
      11: 48.84416853698616,
      12: 0,
      13: 114.6146566849315,
      14: 92.97733944109578,
      15: 131.58949695890414,
      16: 173.53897275068493,
      17: 196.2386762219178,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    acProduced: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 1.35824637260274,
      6: 16.82772386027397,
      7: 57.46919648767127,
      8: 97.96806917260278,
      9: 127.45948443013698,
      10: 143.55190790410958,
      11: 151.15583146301384,
      12: 148.61092561917812,
      13: 135.3853433150685,
      14: 107.02266055890422,
      15: 68.41050304109586,
      16: 26.46102724931508,
      17: 3.7613237780821898,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    usage: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 312.5,
      8: 200,
      9: 200,
      10: 200,
      11: 200,
      12: 100,
      13: 250,
      14: 200,
      15: 200,
      16: 200,
      17: 200,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    upfrontPayment: -26,
    upfrontEquity: 2600000,
    tenure: 5,
    roi: 8.5,
    totalLoanRepaymentPrinciple: -100,
    totalLoanRepaymentInterest: -23.0991879623075,
    totalLoanRepayment: -123.0991879623075,
    totalOperationalExpenses: -15,
    totalOwnershipCost: -164.09918796230747,
    totalBeforeSolarBill: -70.89208106496,
    totalGenerationFromSolar: 95.52337547253703,
    totalAfterSolarBill: 728.3858772133793,
    totalBillSaving: 799.2779582783396,
    totalIncomeTaxBenefitLoan: 5.774796990576875,
    totalDepreciationBenefit: 148.6767273575937,
    totalIncomeTaxBenefitDepreciation: 37.169181839398426,
    totalAdditionalTaxBenefit: 42.9439788299753,
    totalNetSavingFromSolar: 678.1227491460072,
    totalCumulativeSavingFromSolar: 678.1227491460072,
    totalNpv: 106.0414729395269,
    averageRoi: 0.17218314666030107,
    totalCo2Mitigation: 2725.4030782605323,
    totalCo2ExchangePricesAmount: 34031.23400219191,
    totalCo2ExchangeNpv: 13464.918200499234,
  },
  success: true,
  error: false,
};

export const UPFRONT = {
  data: {
    cashflow: {
      0: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -125.6,
        electricityCost: 7.1,
        solarPlantDegradation: 0.99,
        beforeSolarBill: -2.4,
        generationFromSolar: 4.01563234470538,
        netFeedInTariffDiscom: 7.1,
        afterSolarBill: 26.110989647408196,
        billSaving: 28.510989647408195,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 50,
        incomeTaxBenefitDepreciation: 12.5,
        additionalTaxBenefit: 12.5,
        cumulativeSavingFromSolar: -84.58901035259181,
        netSavingFromSolar: -84.58901035259181,
        discountRate: 1,
        npv: -84.58901035259181,
        co2Intensity: 626.5915789473685,
        co2Mitigation: 251.61614113410673,
        co2ExchangePrices: 1.5,
        co2ExchangePricesAmount: 377.42421170116006,
        co2ExchangeNpv: 377.42421170116006,
      },
      1: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.526,
        solarPlantDegradation: 0.986,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.999407567555055,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.555541353419343,
        billSaving: 30.099541353419344,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 15,
        incomeTaxBenefitDepreciation: 3.75,
        additionalTaxBenefit: 3.75,
        cumulativeSavingFromSolar: -51.33946899917247,
        netSavingFromSolar: 33.249541353419346,
        discountRate: 0.8474576271186441,
        npv: 28.17757741815199,
        co2Intensity: 597.1221052631579,
        co2Mitigation: 238.813466654388,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 3248.858202610737,
        co2ExchangeNpv: 2753.2696632294383,
      },
      2: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.526,
        solarPlantDegradation: 0.982,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.9831827904047303,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.433433680586,
        billSaving: 29.977433680586,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 12,
        incomeTaxBenefitDepreciation: 3,
        additionalTaxBenefit: 3,
        cumulativeSavingFromSolar: -18.962035318586466,
        netSavingFromSolar: 32.377433680586,
        discountRate: 0.7181844297615628,
        npv: 23.252968745034476,
        co2Intensity: 567.6526315789473,
        co2Mitigation: 226.10641930332199,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 3075.9894126056092,
        co2ExchangeNpv: 2209.127702244764,
      },
      3: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.526,
        solarPlantDegradation: 0.978,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.966958013254405,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.311326007752655,
        billSaving: 29.855326007752655,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 9.600000000000001,
        incomeTaxBenefitDepreciation: 2.4000000000000004,
        additionalTaxBenefit: 2.4000000000000004,
        cumulativeSavingFromSolar: 12.693290689166192,
        netSavingFromSolar: 31.655326007752656,
        discountRate: 0.6086308726792905,
        npv: 19.26640869304594,
        co2Intensity: 538.1831578947367,
        co2Mitigation: 213.49499908090866,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2904.421549996528,
        co2ExchangeNpv: 1767.7206226029243,
      },
      4: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.526,
        solarPlantDegradation: 0.974,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.950733236104081,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.18921833491931,
        billSaving: 29.73321833491931,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 7.6800000000000015,
        incomeTaxBenefitDepreciation: 1.9200000000000004,
        additionalTaxBenefit: 1.9200000000000004,
        cumulativeSavingFromSolar: 43.746509024085505,
        netSavingFromSolar: 31.05321833491931,
        discountRate: 0.5157888751519412,
        npv: 16.016904554815667,
        co2Intensity: 508.71368421052625,
        co2Mitigation: 200.97920598714816,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2734.1546147834947,
        co2ExchangeNpv: 1410.2465332506677,
      },
      5: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.526,
        solarPlantDegradation: 0.97,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.934508458953756,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.067110662085973,
        billSaving: 29.611110662085974,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 6.144,
        incomeTaxBenefitDepreciation: 1.536,
        additionalTaxBenefit: 1.536,
        cumulativeSavingFromSolar: 74.29361968617147,
        netSavingFromSolar: 30.547110662085974,
        discountRate: 0.4371092162304586,
        npv: 13.352423599609486,
        co2Intensity: 479.2442105263157,
        co2Mitigation: 188.55904002204036,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2565.1886069665075,
        co2ExchangeNpv: 1121.267581474432,
      },
      6: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.966,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.9182836818034312,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.561703168607785,
        billSaving: 31.258343168607784,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 4.915199999999999,
        incomeTaxBenefitDepreciation: 1.2287999999999997,
        additionalTaxBenefit: 1.2287999999999997,
        cumulativeSavingFromSolar: 106.18076285477926,
        netSavingFromSolar: 31.887143168607782,
        discountRate: 0.3704315391783548,
        npv: 11.812003523947942,
        co2Intensity: 449.7747368421052,
        co2Mitigation: 176.23450118558534,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2397.523526545567,
        co2ExchangeNpv: 888.1183301545916,
      },
      7: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.962,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.9020589046531065,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.43226903540444,
        billSaving: 31.128909035404437,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 3.932160000000002,
        incomeTaxBenefitDepreciation: 0.9830400000000005,
        additionalTaxBenefit: 0.9830400000000005,
        cumulativeSavingFromSolar: 137.6927118901837,
        netSavingFromSolar: 31.511949035404434,
        discountRate: 0.31392503320199566,
        npv: 9.892389647198932,
        co2Intensity: 420.30526315789473,
        co2Mitigation: 164.00558947778305,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2231.1593735206734,
        co2ExchangeNpv: 700.4167804114212,
      },
      8: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.958,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.8858341275027812,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.302834902201088,
        billSaving: 30.999474902201086,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 3.145728,
        incomeTaxBenefitDepreciation: 0.786432,
        additionalTaxBenefit: 0.786432,
        cumulativeSavingFromSolar: 168.87861879238477,
        netSavingFromSolar: 31.185906902201086,
        discountRate: 0.26603816373050476,
        npv: 8.296641406532052,
        co2Intensity: 390.8357894736842,
        co2Mitigation: 151.87230489863344,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2066.0961478918257,
        co2ExchangeNpv: 549.6604252758107,
      },
      9: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.954,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.8696093503524565,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.173400768997745,
        billSaving: 30.870040768997743,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 2.516582399999999,
        incomeTaxBenefitDepreciation: 0.6291455999999997,
        additionalTaxBenefit: 0.6291455999999997,
        cumulativeSavingFromSolar: 199.7778051613825,
        netSavingFromSolar: 30.899186368997743,
        discountRate: 0.2254560709580549,
        npv: 6.966409154554918,
        co2Intensity: 361.36631578947373,
        co2Mitigation: 139.83464744813662,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1902.3338496590252,
        co2ExchangeNpv: 428.89271539463493,
      },
      10: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.95,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.853384573202132,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.0439666357944,
        billSaving: 30.7406066357944,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 2.0132659200000007,
        incomeTaxBenefitDepreciation: 0.5033164800000002,
        additionalTaxBenefit: 0.5033164800000002,
        cumulativeSavingFromSolar: 230.4217282771769,
        netSavingFromSolar: 30.6439231157944,
        discountRate: 0.19106446691360587,
        npv: 5.854964834260781,
        co2Intensity: 331.8968421052632,
        co2Mitigation: 127.89261712629252,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1739.8724788222712,
        co2ExchangeNpv: 332.4278076638313,
      },
      11: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.946,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.8371597960518073,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.589404452746525,
        billSaving: 32.447842852746525,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 1.6106127359999989,
        incomeTaxBenefitDepreciation: 0.4026531839999997,
        additionalTaxBenefit: 0.4026531839999997,
        cumulativeSavingFromSolar: 262.67222431392344,
        netSavingFromSolar: 32.250496036746526,
        discountRate: 0.1619190397572931,
        npv: 5.221969349966384,
        co2Intensity: 302.4273684210527,
        co2Mitigation: 116.04621393310113,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1578.7120353815633,
        co2ExchangeNpv: 255.62353682226447,
      },
      12: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.942,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.8209350189014826,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.452204271550983,
        billSaving: 32.31064267155098,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 1.2884901888000002,
        incomeTaxBenefitDepreciation: 0.32212254720000005,
        additionalTaxBenefit: 0.32212254720000005,
        cumulativeSavingFromSolar: 294.7049895326744,
        netSavingFromSolar: 32.03276521875098,
        discountRate: 0.137219525218045,
        npv: 4.395520834738115,
        co2Intensity: 272.9578947368422,
        co2Mitigation: 104.29543786856253,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1418.8525193369028,
        co2ExchangeNpv: 194.69426905783683,
      },
      13: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.938,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.8047102417511574,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.315004090355433,
        billSaving: 32.173442490355434,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 1.030792151039998,
        incomeTaxBenefitDepreciation: 0.2576980377599995,
        additionalTaxBenefit: 0.2576980377599995,
        cumulativeSavingFromSolar: 326.5361300607899,
        netSavingFromSolar: 31.831140528115437,
        discountRate: 0.1162877332356314,
        npv: 3.7015711783193828,
        co2Intensity: 243.48842105263165,
        co2Mitigation: 92.64028893267658,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1260.2939306882877,
        co2ExchangeNpv: 146.55672441036492,
      },
      14: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.9339999999999999,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.7884854646008326,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.177803909159884,
        billSaving: 32.03624230915989,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.8246337208319972,
        incomeTaxBenefitDepreciation: 0.2061584302079993,
        additionalTaxBenefit: 0.2061584302079993,
        cumulativeSavingFromSolar: 358.1785308001578,
        netSavingFromSolar: 31.642400739367886,
        discountRate: 0.09854892647087406,
        npv: 3.1183246238258966,
        co2Intensity: 214.01894736842112,
        co2Mitigation: 81.0807671254434,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1103.0362694357195,
        co2ExchangeNpv: 108.70304021132795,
      },
      15: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.9299999999999999,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.7722606874505082,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.040603727964342,
        billSaving: 31.899042127964343,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.6597069766655977,
        incomeTaxBenefitDepreciation: 0.16492674416639944,
        additionalTaxBenefit: 0.16492674416639944,
        cumulativeSavingFromSolar: 389.64249967228847,
        netSavingFromSolar: 31.463968872130735,
        discountRate: 0.08351603938209666,
        npv: 2.627746063441934,
        co2Intensity: 184.5494736842106,
        co2Mitigation: 69.61687244686298,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 947.0795355791985,
        co2ExchangeNpv: 79.09633179141015,
      },
      16: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9259999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7560359103001835,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.637607759574923,
        billSaving: 33.66755246357492,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.5277655813324799,
        incomeTaxBenefitDepreciation: 0.13194139533311997,
        additionalTaxBenefit: 0.13194139533311997,
        cumulativeSavingFromSolar: 422.8419935311965,
        netSavingFromSolar: 33.19949385890804,
        discountRate: 0.07077630456109887,
        npv: 2.349737488632407,
        co2Intensity: 155.08,
        co2Mitigation: 58.24860489693525,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 792.4237291187233,
        co2ExchangeNpv: 56.08482319354847,
      },
      17: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9219999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7398111331498587,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.492175567507648,
        billSaving: 33.522120271507646,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.4222124650659822,
        incomeTaxBenefitDepreciation: 0.10555311626649555,
        additionalTaxBenefit: 0.10555311626649555,
        cumulativeSavingFromSolar: 455.86966691897067,
        netSavingFromSolar: 33.027673387774136,
        discountRate: 0.059979919119575315,
        npv: 1.9809971785064426,
        co2Intensity: 125.61052631578947,
        co2Mitigation: 46.97596447566028,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 639.068850054295,
        co2ExchangeNpv: 38.33129793809662,
      },
      18: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9179999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7235863559995335,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.34674337544037,
        billSaving: 33.37668807944037,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.3377699720527858,
        incomeTaxBenefitDepreciation: 0.08444249301319645,
        additionalTaxBenefit: 0.08444249301319645,
        cumulativeSavingFromSolar: 488.7307974914242,
        netSavingFromSolar: 32.86113057245356,
        discountRate: 0.050830439931843496,
        npv: 1.6703457236555665,
        co2Intensity: 96.14105263157893,
        co2Mitigation: 35.798951183038035,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 487.0148983859132,
        co2ExchangeNpv: 24.755181538318027,
      },
      19: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9139999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7073615788492087,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.201311183373086,
        billSaving: 33.23125588737309,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.2702159776422292,
        incomeTaxBenefitDepreciation: 0.0675539944105573,
        additionalTaxBenefit: 0.0675539944105573,
        cumulativeSavingFromSolar: 521.4296073732079,
        netSavingFromSolar: 32.698809881783646,
        discountRate: 0.04307664401003686,
        npv: 1.4085549928294694,
        co2Intensity: 66.6715789473684,
        co2Mitigation: 24.717565019068537,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 336.2618741135782,
        co2ExchangeNpv: 14.485033045338435,
      },
      20: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9099999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.6911368016988844,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.055878991305814,
        billSaving: 33.08582369530581,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.21617278211378166,
        incomeTaxBenefitDepreciation: 0.054043195528445415,
        additionalTaxBenefit: 0.054043195528445415,
        cumulativeSavingFromSolar: 553.9694742640421,
        netSavingFromSolar: 32.53986689083426,
        discountRate: 0.03650563051698039,
        npv: 1.187888357788519,
        co2Intensity: 37.20210526315787,
        co2Mitigation: 13.731805983751778,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 186.8097772372898,
        co2ExchangeNpv: 6.819608704783916,
      },
      21: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.9059999999999999,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6749120245485596,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.705073607192855,
        billSaving: 34.91681499343286,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.17293822569102701,
        incomeTaxBenefitDepreciation: 0.043234556422756754,
        additionalTaxBenefit: 0.043234556422756754,
        cumulativeSavingFromSolar: 588.3295238138977,
        netSavingFromSolar: 34.36004954985562,
        discountRate: 0.03093697501439016,
        npv: 1.062995994417091,
        co2Intensity: 7.732631578947345,
        co2Mitigation: 2.8416740770877515,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 38.65860775704795,
        co2ExchangeNpv: 1.195980382270902,
      },
      22: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.9019999999999999,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6586872473982344,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.550915483601536,
        billSaving: 34.762656869841535,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.13835058055281876,
        incomeTaxBenefitDepreciation: 0.03458764513820469,
        additionalTaxBenefit: 0.03458764513820469,
        cumulativeSavingFromSolar: 622.5267683288776,
        netSavingFromSolar: 34.197244514979744,
        discountRate: 0.02621777543592387,
        npv: 0.8965756772211182,
        co2Intensity: 0,
        co2Mitigation: 0,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 0,
        co2ExchangeNpv: 0,
      },
      23: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.8979999999999999,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6424624702479096,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.39675736001022,
        billSaving: 34.60849874625022,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.11068046444225388,
        incomeTaxBenefitDepreciation: 0.02767011611056347,
        additionalTaxBenefit: 0.02767011611056347,
        cumulativeSavingFromSolar: 656.5629371912382,
        netSavingFromSolar: 34.03616886236078,
        discountRate: 0.022218453759257517,
        npv: 0.7562310440106436,
        co2Intensity: 0,
        co2Mitigation: 0,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 0,
        co2ExchangeNpv: 0,
      },
      24: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.894,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6262376930975857,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.242599236418908,
        billSaving: 34.45434062265891,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0.08854437155380311,
        incomeTaxBenefitDepreciation: 0.022136092888450778,
        additionalTaxBenefit: 0.022136092888450778,
        cumulativeSavingFromSolar: 690.4394139067856,
        netSavingFromSolar: 33.87647671554737,
        discountRate: 0.018829198101065692,
        npv: 0.6378668910431806,
        co2Intensity: 0,
        co2Mitigation: 0,
        co2ExchangePrices: 292,
        co2ExchangePricesAmount: 0,
        co2ExchangeNpv: 0,
      },
    },
    paybackPeriod: 44,
    processingFee: 0.01,
    consumptionToGenerationRation: 0.7790512890552613,
    netMetering: 0.22094871094473867,
    connectedLoadUtilizationRatio: 0.31303082191780823,
    consumptionSum: 2262.5,
    consumption: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 57.46919648767127,
      8: 97.96806917260278,
      9: 127.45948443013698,
      10: 143.55190790410958,
      11: 151.15583146301384,
      12: 100,
      13: 135.3853433150685,
      14: 107.02266055890422,
      15: 68.41050304109586,
      16: 26.46102724931508,
      17: 3.7613237780821898,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    exported: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 1.35824637260274,
      6: 16.82772386027397,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 48.61092561917812,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    imported: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 255.03080351232873,
      8: 102.03193082739722,
      9: 72.54051556986302,
      10: 56.448092095890416,
      11: 48.84416853698616,
      12: 0,
      13: 114.6146566849315,
      14: 92.97733944109578,
      15: 131.58949695890414,
      16: 173.53897275068493,
      17: 196.2386762219178,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    acProduced: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 1.35824637260274,
      6: 16.82772386027397,
      7: 57.46919648767127,
      8: 97.96806917260278,
      9: 127.45948443013698,
      10: 143.55190790410958,
      11: 151.15583146301384,
      12: 148.61092561917812,
      13: 135.3853433150685,
      14: 107.02266055890422,
      15: 68.41050304109586,
      16: 26.46102724931508,
      17: 3.7613237780821898,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    usage: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 312.5,
      8: 200,
      9: 200,
      10: 200,
      11: 200,
      12: 100,
      13: 250,
      14: 200,
      15: 200,
      16: 200,
      17: 200,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    upfrontPayment: -125,
    upfrontEquity: 12500000,
    tenure: 5,
    roi: 8.5,
    totalLoanRepaymentPrinciple: 0,
    totalLoanRepaymentInterest: 0,
    totalLoanRepayment: 0,
    totalOperationalExpenses: -15,
    totalOwnershipCost: -139.99999999999997,
    totalBeforeSolarBill: -70.89208106496,
    totalGenerationFromSolar: 95.52337547253703,
    totalAfterSolarBill: 728.3858772133793,
    totalBillSaving: 799.2779582783396,
    totalIncomeTaxBenefitLoan: 0,
    totalDepreciationBenefit: 124.64582251378478,
    totalIncomeTaxBenefitDepreciation: 31.161455628446195,
    totalAdditionalTaxBenefit: 31.161455628446195,
    totalNetSavingFromSolar: 690.4394139067856,
    totalCumulativeSavingFromSolar: 690.4394139067856,
    totalNpv: 89.31600662295651,
    averageRoi: 0.205487920805591,
    totalCo2Mitigation: 2725.4030782605323,
    totalCo2ExchangePricesAmount: 34031.23400219191,
    totalCo2ExchangeNpv: 13464.918200499234,
  },
  success: true,
  error: false,
};

export const RESCO = {
  data: {
    cashflow: {
      0: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.1,
        solarPlantDegradation: 0.99,
        beforeSolarBill: -2.4,
        generationFromSolar: 4.01563234470538,
        netFeedInTariffDiscom: 7.1,
        afterSolarBill: 26.110989647408196,
        billSaving: 28.510989647408195,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 27.910989647408194,
        netSavingFromSolar: 27.910989647408194,
        discountRate: 1,
        npv: 27.910989647408194,
        co2Intensity: 626.5915789473685,
        co2Mitigation: 251.61614113410673,
        co2ExchangePrices: 1.5,
        co2ExchangePricesAmount: 377.42421170116006,
        co2ExchangeNpv: 377.42421170116006,
      },
      1: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.526,
        solarPlantDegradation: 0.986,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.999407567555055,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.555541353419343,
        billSaving: 30.099541353419344,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 57.41053100082754,
        netSavingFromSolar: 29.499541353419342,
        discountRate: 0.8474576271186441,
        npv: 24.999611316457074,
        co2Intensity: 597.1221052631579,
        co2Mitigation: 238.813466654388,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 3248.858202610737,
        co2ExchangeNpv: 2753.2696632294383,
      },
      2: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.526,
        solarPlantDegradation: 0.982,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.9831827904047303,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.433433680586,
        billSaving: 29.977433680586,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 86.78796468141356,
        netSavingFromSolar: 29.377433680586,
        discountRate: 0.7181844297615628,
        npv: 21.098415455749787,
        co2Intensity: 567.6526315789473,
        co2Mitigation: 226.10641930332199,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 3075.9894126056092,
        co2ExchangeNpv: 2209.127702244764,
      },
      3: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.526,
        solarPlantDegradation: 0.978,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.966958013254405,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.311326007752655,
        billSaving: 29.855326007752655,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 116.0432906891662,
        netSavingFromSolar: 29.255326007752654,
        discountRate: 0.6086308726792905,
        npv: 17.80569459861564,
        co2Intensity: 538.1831578947367,
        co2Mitigation: 213.49499908090866,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2904.421549996528,
        co2ExchangeNpv: 1767.7206226029243,
      },
      4: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.526,
        solarPlantDegradation: 0.974,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.950733236104081,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.18921833491931,
        billSaving: 29.73321833491931,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 145.1765090240855,
        netSavingFromSolar: 29.13321833491931,
        discountRate: 0.5157888751519412,
        npv: 15.026589914523939,
        co2Intensity: 508.71368421052625,
        co2Mitigation: 200.97920598714816,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2734.1546147834947,
        co2ExchangeNpv: 1410.2465332506677,
      },
      5: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.526,
        solarPlantDegradation: 0.97,
        beforeSolarBill: -2.544,
        generationFromSolar: 3.934508458953756,
        netFeedInTariffDiscom: 7.526,
        afterSolarBill: 27.067110662085973,
        billSaving: 29.611110662085974,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 174.18761968617147,
        netSavingFromSolar: 29.011110662085972,
        discountRate: 0.4371092162304586,
        npv: 12.681023843479501,
        co2Intensity: 479.2442105263157,
        co2Mitigation: 188.55904002204036,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2565.1886069665075,
        co2ExchangeNpv: 1121.267581474432,
      },
      6: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.966,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.9182836818034312,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.561703168607785,
        billSaving: 31.258343168607784,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 204.84596285477926,
        netSavingFromSolar: 30.658343168607782,
        discountRate: 0.3704315391783548,
        npv: 11.35681724860558,
        co2Intensity: 449.7747368421052,
        co2Mitigation: 176.23450118558534,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2397.523526545567,
        co2ExchangeNpv: 888.1183301545916,
      },
      7: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.962,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.9020589046531065,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.43226903540444,
        billSaving: 31.128909035404437,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 235.37487189018373,
        netSavingFromSolar: 30.52890903540444,
        discountRate: 0.31392503320199566,
        npv: 9.583788782560044,
        co2Intensity: 420.30526315789473,
        co2Mitigation: 164.00558947778305,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2231.1593735206734,
        co2ExchangeNpv: 700.4167804114212,
      },
      8: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.958,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.8858341275027812,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.302834902201088,
        billSaving: 30.999474902201086,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 265.7743467923848,
        netSavingFromSolar: 30.39947490220109,
        discountRate: 0.26603816373050476,
        npv: 8.087420481353144,
        co2Intensity: 390.8357894736842,
        co2Mitigation: 151.87230489863344,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 2066.0961478918257,
        co2ExchangeNpv: 549.6604252758107,
      },
      9: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.954,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.8696093503524565,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.173400768997745,
        billSaving: 30.870040768997743,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 296.04438756138256,
        netSavingFromSolar: 30.270040768997745,
        discountRate: 0.2254560709580549,
        npv: 6.8245644595183705,
        co2Intensity: 361.36631578947373,
        co2Mitigation: 139.83464744813662,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1902.3338496590252,
        co2ExchangeNpv: 428.89271539463493,
      },
      10: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 7.97756,
        solarPlantDegradation: 0.95,
        beforeSolarBill: -2.69664,
        generationFromSolar: 3.853384573202132,
        netFeedInTariffDiscom: 7.97756,
        afterSolarBill: 28.0439666357944,
        billSaving: 30.7406066357944,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 326.18499419717693,
        netSavingFromSolar: 30.1406066357944,
        discountRate: 0.19106446691360587,
        npv: 5.758798939320749,
        co2Intensity: 331.8968421052632,
        co2Mitigation: 127.89261712629252,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1739.8724788222712,
        co2ExchangeNpv: 332.4278076638313,
      },
      11: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.946,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.8371597960518073,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.589404452746525,
        billSaving: 32.447842852746525,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 358.0328370499235,
        netSavingFromSolar: 31.847842852746524,
        discountRate: 0.1619190397572931,
        npv: 5.1567721330578875,
        co2Intensity: 302.4273684210527,
        co2Mitigation: 116.04621393310113,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1578.7120353815633,
        co2ExchangeNpv: 255.62353682226447,
      },
      12: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.942,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.8209350189014826,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.452204271550983,
        billSaving: 32.31064267155098,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 389.74347972147444,
        netSavingFromSolar: 31.710642671550982,
        discountRate: 0.137219525218045,
        npv: 4.351319331749304,
        co2Intensity: 272.9578947368422,
        co2Mitigation: 104.29543786856253,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1418.8525193369028,
        co2ExchangeNpv: 194.69426905783683,
      },
      13: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.938,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.8047102417511574,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.315004090355433,
        billSaving: 32.173442490355434,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 421.31692221182993,
        netSavingFromSolar: 31.573442490355436,
        discountRate: 0.1162877332356314,
        npv: 3.6716040576490023,
        co2Intensity: 243.48842105263165,
        co2Mitigation: 92.64028893267658,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1260.2939306882877,
        co2ExchangeNpv: 146.55672441036492,
      },
      14: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.9339999999999999,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.7884854646008326,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.177803909159884,
        billSaving: 32.03624230915989,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 452.7531645209898,
        netSavingFromSolar: 31.43624230915989,
        discountRate: 0.09854892647087406,
        npv: 3.098007931845978,
        co2Intensity: 214.01894736842112,
        co2Mitigation: 81.0807671254434,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 1103.0362694357195,
        co2ExchangeNpv: 108.70304021132795,
      },
      15: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.456213600000002,
        solarPlantDegradation: 0.9299999999999999,
        beforeSolarBill: -2.8584384000000007,
        generationFromSolar: 3.7722606874505082,
        netFeedInTariffDiscom: 8.456213600000002,
        afterSolarBill: 29.040603727964342,
        billSaving: 31.899042127964343,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 484.05220664895415,
        netSavingFromSolar: 31.29904212796434,
        discountRate: 0.08351603938209666,
        npv: 2.6139720349809723,
        co2Intensity: 184.5494736842106,
        co2Mitigation: 69.61687244686298,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 947.0795355791985,
        co2ExchangeNpv: 79.09633179141015,
      },
      16: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9259999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7560359103001835,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.637607759574923,
        billSaving: 33.66755246357492,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 517.1197591125291,
        netSavingFromSolar: 33.06755246357493,
        discountRate: 0.07077630456109887,
        npv: 2.3403991642520943,
        co2Intensity: 155.08,
        co2Mitigation: 58.24860489693525,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 792.4237291187233,
        co2ExchangeNpv: 56.08482319354847,
      },
      17: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9219999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7398111331498587,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.492175567507648,
        billSaving: 33.522120271507646,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 550.0418793840367,
        netSavingFromSolar: 32.922120271507644,
        discountRate: 0.059979919119575315,
        npv: 1.9746661111299595,
        co2Intensity: 125.61052631578947,
        co2Mitigation: 46.97596447566028,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 639.068850054295,
        co2ExchangeNpv: 38.33129793809662,
      },
      18: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9179999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7235863559995335,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.34674337544037,
        billSaving: 33.37668807944037,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 582.8185674634772,
        netSavingFromSolar: 32.77668807944037,
        discountRate: 0.050830439931843496,
        npv: 1.6660534745867643,
        co2Intensity: 96.14105263157893,
        co2Mitigation: 35.798951183038035,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 487.0148983859132,
        co2ExchangeNpv: 24.755181538318027,
      },
      19: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9139999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.7073615788492087,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.201311183373086,
        billSaving: 33.23125588737309,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 615.4498233508502,
        netSavingFromSolar: 32.63125588737309,
        discountRate: 0.04307664401003686,
        npv: 1.4056449934607897,
        co2Intensity: 66.6715789473684,
        co2Mitigation: 24.717565019068537,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 336.2618741135782,
        co2ExchangeNpv: 14.485033045338435,
      },
      20: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 8.963586416000002,
        solarPlantDegradation: 0.9099999999999999,
        beforeSolarBill: -3.0299447040000005,
        generationFromSolar: 3.6911368016988844,
        netFeedInTariffDiscom: 8.963586416000002,
        afterSolarBill: 30.055878991305814,
        billSaving: 33.08582369530581,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 647.9356470461561,
        netSavingFromSolar: 32.48582369530582,
        discountRate: 0.03650563051698039,
        npv: 1.1859154768606008,
        co2Intensity: 37.20210526315787,
        co2Mitigation: 13.731805983751778,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 186.8097772372898,
        co2ExchangeNpv: 6.819608704783916,
      },
      21: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.9059999999999999,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6749120245485596,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.705073607192855,
        billSaving: 34.91681499343286,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 682.252462039589,
        netSavingFromSolar: 34.316814993432864,
        discountRate: 0.03093697501439016,
        npv: 1.0616584480252822,
        co2Intensity: 7.732631578947345,
        co2Mitigation: 2.8416740770877515,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 38.65860775704795,
        co2ExchangeNpv: 1.195980382270902,
      },
      22: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.9019999999999999,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6586872473982344,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.550915483601536,
        billSaving: 34.762656869841535,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 716.4151189094305,
        netSavingFromSolar: 34.162656869841534,
        discountRate: 0.02621777543592387,
        npv: 0.8956688661080272,
        co2Intensity: 0,
        co2Mitigation: 0,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 0,
        co2ExchangeNpv: 0,
      },
      23: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.8979999999999999,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6424624702479096,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.39675736001022,
        billSaving: 34.60849874625022,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 750.4236176556807,
        netSavingFromSolar: 34.00849874625022,
        discountRate: 0.022218453759257517,
        npv: 0.7556162568153277,
        co2Intensity: 0,
        co2Mitigation: 0,
        co2ExchangePrices: 13.604166666666666,
        co2ExchangePricesAmount: 0,
        co2ExchangeNpv: 0,
      },
      24: {
        loanRepaymentPrinciple: 0,
        loanRepaymentInterest: 0,
        loanRepayment: 0,
        operationalExpenses: -0.6,
        ownershipCost: -0.6,
        electricityCost: 9.501401600960003,
        solarPlantDegradation: 0.894,
        beforeSolarBill: -3.211741386240001,
        generationFromSolar: 3.6262376930975857,
        netFeedInTariffDiscom: 9.501401600960003,
        afterSolarBill: 31.242599236418908,
        billSaving: 34.45434062265891,
        incomeTaxBenefitLoan: 0,
        depreciationBenefit: 0,
        incomeTaxBenefitDepreciation: 0,
        additionalTaxBenefit: 0,
        cumulativeSavingFromSolar: 784.2779582783396,
        netSavingFromSolar: 33.85434062265892,
        discountRate: 0.018829198101065692,
        npv: 0.6374500861650004,
        co2Intensity: 0,
        co2Mitigation: 0,
        co2ExchangePrices: 292,
        co2ExchangePricesAmount: 0,
        co2ExchangeNpv: 0,
      },
    },
    paybackPeriod: 12,
    processingFee: 0.01,
    consumptionToGenerationRation: 0.7790512890552613,
    netMetering: 0.22094871094473867,
    connectedLoadUtilizationRatio: 0.31303082191780823,
    consumptionSum: 2262.5,
    consumption: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 57.46919648767127,
      8: 97.96806917260278,
      9: 127.45948443013698,
      10: 143.55190790410958,
      11: 151.15583146301384,
      12: 100,
      13: 135.3853433150685,
      14: 107.02266055890422,
      15: 68.41050304109586,
      16: 26.46102724931508,
      17: 3.7613237780821898,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    exported: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 1.35824637260274,
      6: 16.82772386027397,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 48.61092561917812,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    imported: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 255.03080351232873,
      8: 102.03193082739722,
      9: 72.54051556986302,
      10: 56.448092095890416,
      11: 48.84416853698616,
      12: 0,
      13: 114.6146566849315,
      14: 92.97733944109578,
      15: 131.58949695890414,
      16: 173.53897275068493,
      17: 196.2386762219178,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    acProduced: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 1.35824637260274,
      6: 16.82772386027397,
      7: 57.46919648767127,
      8: 97.96806917260278,
      9: 127.45948443013698,
      10: 143.55190790410958,
      11: 151.15583146301384,
      12: 148.61092561917812,
      13: 135.3853433150685,
      14: 107.02266055890422,
      15: 68.41050304109586,
      16: 26.46102724931508,
      17: 3.7613237780821898,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    usage: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 312.5,
      8: 200,
      9: 200,
      10: 200,
      11: 200,
      12: 100,
      13: 250,
      14: 200,
      15: 200,
      16: 200,
      17: 200,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
    },
    upfrontPayment: 0,
    upfrontEquity: 12500000,
    tenure: 5,
    roi: 8.5,
    totalLoanRepaymentPrinciple: 0,
    totalLoanRepaymentInterest: 0,
    totalLoanRepayment: 0,
    totalOperationalExpenses: -12,
    totalOwnershipCost: 24,
    totalBeforeSolarBill: -70.89208106496,
    totalGenerationFromSolar: 95.52337547253703,
    totalAfterSolarBill: 728.3858772133793,
    totalBillSaving: 651.1014492542539,
    totalIncomeTaxBenefitLoan: 0,
    totalDepreciationBenefit: 0,
    totalIncomeTaxBenefitDepreciation: 0,
    totalAdditionalTaxBenefit: 0,
    totalNetSavingFromSolar: 627.1014492542539,
    totalCumulativeSavingFromSolar: 784.2779582783396,
    totalNpv: 191.94846305427907,
    averageRoi: 1.0887177938441908,
    totalCo2Mitigation: 2725.4030782605323,
    totalCo2ExchangePricesAmount: 34031.23400219191,
    totalCo2ExchangeNpv: 13464.918200499234,
  },
  success: true,
  error: false,
};
