import React from "react";
import "./index.css";

const SelectionModal = (props) => {
  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <span onClick={() => props.setShowModal(false)} className="close">
          &times;
        </span>
        <p>
          Enter your address in the searchbar or detect your location and then
          select your rooftop area by clicking on each corner of your roof like
          below.
        </p>
        <img
          className="w-100 asc mt-10 mb-10"
          src={require("../../assets/gif/map-marking.gif")}
          //    type="video/gif"
        />
      </div>
    </div>
  );
};

export default SelectionModal;
