import axios from "axios";
import { CONFIG } from "../generic/config";

export const login = async (auth) =>
  axios.post(`${CONFIG.BASE_URL}auth/login`, auth);

export const signup = async (auth) =>
  axios.post(`${CONFIG.BASE_URL}auth/signup`, auth);

export const changePassword = async (auth) =>
  axios.post(`${CONFIG.BASE_URL}auth/changePassword`, auth);

export const verifyEmail = async (auth) =>
  axios.post(`${CONFIG.BASE_URL}auth/verifyEmail/changePassword`, auth);
