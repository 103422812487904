import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CONFIG } from "../../generic/config";
import { logEvent, getAnalytics } from "firebase/analytics";
import { useDispatch, useSelector } from "react-redux";
import { STATE_STATUS } from "../../redux/constants";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Label,
} from "recharts";

import {
  fetchUserDetails,
  fetchUserFinancials,
} from "../../redux/actions/user";
import { OrderedMap } from "immutable";
import { PAYMENT_MODES } from "../../constants";

const Output = (props) => {
  const checkoutRef = useRef();

  const [isAcceptance, setIsAcceptance] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [carousel, setCarousel] = useState({
    wl1: true,
    wl2: true,
    wor1: true,
    wor2: true,
  });
  const userDetailsStatus = useSelector((state) =>
    state?.user.getIn(["userDetails", "status"])
  );
  const userDetailsData = useSelector((state) =>
    state?.user.getIn(["userDetails", "data"])
  );

  // const userFinancialsWithLoanStatus = useSelector((state) =>
  //   state?.user.getIn(["userFinancials", PAYMENT_MODES.LOAN, "status"])
  // );
  const userFinancialsWithoutLoanStatus = useSelector((state) =>
    state?.user.getIn(["userFinancials", PAYMENT_MODES.UPFRONT, "status"])
  );
  const userFinancialsRescoStatus = useSelector((state) =>
    state?.user.getIn(["userFinancials", PAYMENT_MODES.RESCO, "status"])
  );
  // const userFinancialsWithLoanData = useSelector((state) =>
  //   state?.user.getIn(["userFinancials", PAYMENT_MODES.LOAN, "data"])
  // );
  const userFinancialsWithoutLoanData = useSelector((state) =>
    state?.user.getIn(["userFinancials", PAYMENT_MODES.UPFRONT, "data"])
  );
  const userFinancialsRescoData = useSelector((state) =>
    state?.user.getIn(["userFinancials", PAYMENT_MODES.RESCO, "data"])
  );

  const dispatch = useDispatch();

  const analytics = getAnalytics();
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state;
  // const { data } = state;

  const [initLoader, setInitLoader] = useState(true);
  const [moduleType, setModuleType] = useState(1);

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      name: "GenerationResult1",
    });
    if (userDetailsStatus == STATE_STATUS.FETCHED) {
      setInitLoader(false);
    } else {
      dispatch(fetchUserDetails());
    }
  }, []);

  useEffect(() => {
    if (selectedPlan) {
      checkoutRef?.current?.scrollIntoView();
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (userDetailsStatus == STATE_STATUS.FETCHED) {
      if (!userDetailsData?.size) {
        navigate("/onboarding", {
          state: {},
        });
      } else {
        // onGetFinancials();
      }
    }
  }, [userDetailsStatus]);

  useEffect(() => {
    if (userDetailsStatus == STATE_STATUS.FETCHED) {
      if (userDetailsData?.size) {
        onGetFinancials();
      }
    }
  }, [moduleType, userDetailsStatus]);

  useEffect(() => {
    if (
      // userFinancialsWithLoanStatus == STATE_STATUS.FETCHED &&
      userFinancialsWithoutLoanStatus == STATE_STATUS.FETCHED &&
      userFinancialsRescoStatus == STATE_STATUS.FETCHED
    ) {
      setInitLoader(false);
    }
  }, [
    // userFinancialsWithLoanStatus,
    userFinancialsWithoutLoanStatus,
    userFinancialsRescoStatus,
  ]);

  const onGetFinancials = () => {
    if (
      // ![STATE_STATUS.FETCHING].includes(userFinancialsWithLoanStatus) ||
      ![STATE_STATUS.FETCHING].includes(userFinancialsWithoutLoanStatus) ||
      ![STATE_STATUS.FETCHING].includes(userFinancialsRescoStatus)
    ) {
      let generatedData = {
        // {
        // moduleType: 1,
        // startTime: 8,
        // endTime: 20,
        // sanctionedLoad: 250,
        // averageElectricityBill: 250000,
        // connectedLoad: 250,
        // workingOffInYear: 104,
        // holidaysInYear: 10,
        // modeType: "LOAN",
        // tenure: 5,
        // hourlyAC: {
        //   0: 0,
        //   1: 0,
        //   2: 0,
        //   3: 0,
        //   4: 0,
        //   5: 0.7078189369863013,
        //   6: 7.375704687671228,
        //   7: 19.46107139726029,
        //   8: 29.527785986301367,
        //   9: 35.67384064931508,
        //   10: 38.77495146301373,
        //   11: 38.45670332328766,
        //   12: 36.161428912328745,
        //   13: 31.206375939726016,
        //   14: 22.877361712328764,
        //   15: 12.714726621917803,
        //   16: 4.207381942465753,
        //   17: 0.8688163561643836,
        //   18: 0,
        //   19: 0,
        //   20: 0,
        //   21: 0,
        //   22: 0,
        //   23: 0,
        //   // }
        // },
        industryType: userDetailsData.getIn(["0", "industry_type"]),
        startTime: userDetailsData.getIn(["0", "from_time"]),
        endTime: userDetailsData.getIn(["0", "to_time"]),
        sanctionedLoad: userDetailsData.getIn(["0", "sanctioned_load"]),
        connectedLoad: Number(userDetailsData.getIn(["0", "system_capacity"])),
        workingOffInYear: 52,
        holidaysInYear: 10,
        loanAvailed: true,
        tenure: 5,
        moduleType: moduleType,
        averageElectricityBill: userDetailsData.getIn([
          "0",
          "avg_monthly_bill",
        ]),
        perUnitCost: Number(userDetailsData.getIn(["0", "per_unit_cost"]) || 0),
        hourlyAC: userDetailsData.getIn(["0", "generation_data", "ac"]),
      };
      dispatch(
        fetchUserFinancials(
          { ...generatedData, modeType: PAYMENT_MODES.LOAN },
          PAYMENT_MODES.LOAN
        )
      );
      // dispatch(fetchUserFinancials(generatedData, "withLoan"));
      dispatch(
        fetchUserFinancials(
          { ...generatedData, modeType: PAYMENT_MODES.UPFRONT },
          PAYMENT_MODES.UPFRONT
        )
      );
      dispatch(
        fetchUserFinancials(
          { ...generatedData, modeType: PAYMENT_MODES.RESCO },
          PAYMENT_MODES.RESCO
        )
      );
    }
  };

  const getCost = () => {
    return Number();
    // Number(CONFIG.solarPanelUnitPrice) * Number(data?.inputs?.panels)
  };

  // const getPerWattCost = () => {
  //   const cost = getCost();
  //   const hoursInYear = 6 * 365; //Assuming a typical solar panel operates at peak efficiency for about 6 hours per day on average.
  //   const totalWattageCap =
  //     (Number(data?.outputs?.ac_annual)?.toFixed(0) / hoursInYear) * 10000;
  //   return Number(cost / totalWattageCap)?.toFixed(0);
  // };

  const currencyFormatter = (val) => {
    let FormattedCurrency = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      useGrouping: true,
      maximumFractionDigits: 0,
    });
    return FormattedCurrency.format(Number(val)?.toFixed(0) * 100000);
  };

  // const chartWithLoandata = new OrderedMap(
  //   userFinancialsWithLoanData.get("cashflow")
  // )
  //   .toList()
  //   .map((_, k) => ({
  //     name: new Date().getFullYear() + k,
  //     "Saving From Solar": _?.cumulativeSavingFromSolar?.toFixed(2),
  //   }))
  //   .toArray();

  const chartWithoutLoandata = new OrderedMap(
    userFinancialsWithoutLoanData.get("cashflow")
  )
    .toList()
    .map((_, k) => ({
      name: new Date().getFullYear() + k,
      "Saving From Solar": _?.cumulativeSavingFromSolar?.toFixed(2),
    }))
    .toArray();

  const chartRescodata = new OrderedMap(userFinancialsRescoData.get("cashflow"))
    .toList()
    .map((_, k) => ({
      name: new Date().getFullYear() + k,
      "Saving From Solar": _?.cumulativeSavingFromSolar?.toFixed(2),
    }))
    .toArray();

  const renderChart = (chartData, title) => {
    const dataMax = Math.max(
      ...[
        ...chartWithoutLoandata,
        //  ...chartWithLoandata,
        ...chartRescodata,
      ].map((_) => _?.["Saving From Solar"])
    );
    const dataMin = Math.min(
      ...[
        // ...chartWithLoandata,
        ...chartWithoutLoandata,
        ...chartRescodata,
      ].map((_) => _?.["Saving From Solar"])
    );
    // let off;
    // if (dataMax <= 0) {
    //   off = 0;
    // }
    // if (dataMin >= 0) {
    //   off = 1;
    // }

    // off = dataMax / (dataMax - dataMin);
    return (
      <ResponsiveContainer width={"90%"} height={180}>
        <AreaChart
          data={chartData}
          className="mt-20"
          margin={{
            top: 10,
            // right: 10,
            left: 10,
            bottom: 20,
          }}
        >
          <XAxis dataKey="name">
            <Label
              color="#82ca9d"
              className="colorGreen"
              value={title}
              offset={0}
              position="bottom"
            />
          </XAxis>
          <YAxis
            tickFormatter={(label) => label + "L"}
            domain={[dataMin > 0 ? 0 : dataMin, dataMax + 2]}
          />
          <Tooltip
            formatter={(label, key) => {
              return "₹" + label + "L";
            }}
          />
          {/* <defs>
            <linearGradient id="splitColor" x1="1" y1="0" x2="0" y2="1">
              <stop offset={off} stopColor="#82ca9d" stopOpacity={1} />
              <stop offset={off} stopColor="#c41a31" stopOpacity={1} />
            </linearGradient>
          </defs> */}
          <Area
            type="natural"
            dataKey="Saving From Solar"
            // fill="url(#splitColor)"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  const TABLE_DATA = new OrderedMap({
    upfrontPayment: {
      title: "Upfront payment",
      upfront: (data) => currencyFormatter(-data.getIn(["upfrontPayment"])),
      loan: (data) => currencyFormatter(-data.getIn(["upfrontPayment"])),
      noUpfront: (data) => currencyFormatter(-data.getIn(["upfrontPayment"])),
      color: "colorRed",
    },
    totalBeforeSolarBill: {
      title: "Before Solar - electricity bill (25 Yrs)",
      upfront: (data) =>
        currencyFormatter(-data.getIn(["totalBeforeSolarBill"])),
      loan: (data) => currencyFormatter(-data.getIn(["totalBeforeSolarBill"])),
      noUpfront: (data) =>
        currencyFormatter(-data.getIn(["totalBeforeSolarBill"])),
      color: "colorRed",
    },
    totalAfterSolarBill: {
      title: "After Solar - electricity bill (25 Yrs)",
      upfront: (data) =>
        currencyFormatter(
          data.getIn(["totalAfterSolarBill"]) > 0
            ? data.getIn(["totalAfterSolarBill"])
            : -data.getIn(["totalAfterSolarBill"])
        ),
      loan: (data) =>
        currencyFormatter(
          data.getIn(["totalAfterSolarBill"]) > 0
            ? data.getIn(["totalAfterSolarBill"])
            : -data.getIn(["totalAfterSolarBill"])
        ),
      noUpfront: (data) =>
        currencyFormatter(
          data.getIn(["totalAfterSolarBill"]) > 0
            ? data.getIn(["totalAfterSolarBill"])
            : -data.getIn(["totalAfterSolarBill"])
        ),
      color: "colorRed",
    },
    totalAdditionalTaxBenefit: {
      title: "Additional savings in tax (25 Yrs)",
      upfront: (data) =>
        currencyFormatter(data.getIn(["totalAdditionalTaxBenefit"])),
      loan: (data) =>
        currencyFormatter(data.getIn(["totalAdditionalTaxBenefit"])),
      noUpfront: (data) =>
        currencyFormatter(data.getIn(["totalAdditionalTaxBenefit"])),
      color: "colorGreen",
    },
    totalBillSaving: {
      title: "Total savings from Solar (25 Yrs)",
      upfront: (data) => currencyFormatter(data.getIn(["totalBillSaving"])),
      loan: (data) => currencyFormatter(data.getIn(["totalBillSaving"])),
      noUpfront: (data) => currencyFormatter(data.getIn(["totalBillSaving"])),
      color: "colorGreen",
    },
    // {
    //   title: "Loan repayment",
    //   upfront: (data) => currencyFormatter(-data.getIn(["totalLoanRepayment"])),
    //   loan: (data) => currencyFormatter(-data.getIn(["totalLoanRepayment"])),
    //   noUpfront: (data) =>
    //     currencyFormatter(-data.getIn(["totalLoanRepayment"])),
    //   color: "colorRed",
    // },
    // {
    //   title: "Operational expense, AMC (25 Yrs)",
    //   upfront: (data) =>
    //     currencyFormatter(-data.getIn(["totalOperationalExpenses"])),
    //   loan: (data) =>
    //     currencyFormatter(-data.getIn(["totalOperationalExpenses"])),
    //   noUpfront: (data) =>
    //     currencyFormatter(-data.getIn(["totalOperationalExpenses"])),
    //   color: "colorRed",
    // },
    totalOwnershipCost: {
      title: "Total ownership expense (25 Yrs)",
      upfront: (data) =>
        currencyFormatter(Math.abs(data.getIn(["totalOwnershipCost"]))),
      loan: (data) =>
        currencyFormatter(Math.abs(data.getIn(["totalOwnershipCost"]))),
      noUpfront: (data) =>
        currencyFormatter(Math.abs(data.getIn(["totalOwnershipCost"]))),
      color: "colorRed",
    },
    nettSaving: {
      title: "Nett savings from Solar (25 Yrs)",
      upfront: (data) =>
        currencyFormatter(data.getIn(["totalNetSavingFromSolar"])),
      loan: (data) =>
        currencyFormatter(data.getIn(["totalNetSavingFromSolar"])),
      noUpfront: (data) =>
        currencyFormatter(data.getIn(["totalNetSavingFromSolar"])),
      color: "colorGreen",
    },
    roi: {
      title: "ROI",
      upfront: (data) => (data.getIn(["averageRoi"]) * 100)?.toFixed(0) + "%",
      loan: (data) => (data.getIn(["averageRoi"]) * 100)?.toFixed(0) + "%",
      noUpfront: (data) => (data.getIn(["averageRoi"]) * 100)?.toFixed(0) + "%",
      color: "colorGreen",
    },
  });

  const checkPlaceholderValue = (val) => {
    if (isNaN(val) || !val) {
      return "--";
    } else {
      return val;
    }
  };

  const renderTableData = () => {
    return (
      <div className="w-100 mt-20">
        <table id="tableData">
          <tr>
            <th className="boldFont"></th>
            <th className="boldFont">Full Upfront Payment</th>
            <th className="boldFont">With Easy Loan</th>
            <th className="boldFont">No Upfront Payment</th>
          </tr>
          {TABLE_DATA.map((row, rowKey) => (
            <tr key={rowKey}>
              <td className={row?.color}>{row?.title}</td>
              <td className={row?.color}>
                {row?.upfront(userFinancialsWithoutLoanData)}
              </td>
              {/* <td className={row?.color}>
                {row?.loan(userFinancialsWithLoanData)}
              </td> */}
              <td className={row?.color}>
                {row?.noUpfront(userFinancialsRescoData)}
              </td>
            </tr>
          ))}
        </table>
      </div>
      // <div className="w-100">
      //   {isWithLoad ? (
      //     <>
      //       <div className=" mt-10 df fdr alic jcsb">
      //         <h5 className="">BUY WITH EASY LOAN</h5>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <h5 className="">
      //           Easy loans starting {tableData.getIn(["roi"])}
      //           %* for {tableData.getIn(["tenure"])} years
      //         </h5>
      //       </div>
      //     </>
      //   ) : (
      //     <>
      //       <div className=" mt-10 df fdr alic jcsb">
      //         <h5 className="">BUY WITH FULL UPFRONT PAYMENT</h5>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <h5 className="">One time payment, maximum savings!</h5>
      //       </div>
      //     </>
      //   )}
      //   <div className="mt-05 df fdr alic jcsb mt-10">
      //     <div className="df fdr jcsb w-95">
      //       <h5 className="colorGreen">Upfront payment</h5>
      //       <h5 className="colorRed boldFont">
      //         {currencyFormatter(-tableData.getIn(["upfrontPayment"]))}
      //       </h5>
      //     </div>
      //   </div>
      //   {carousel?.[isWithLoad ? "wl1" : "wor1"] ? (
      //     <>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen">
      //             Before Solar - electricity bill (25 Yrs)
      //           </p>
      //           <p className="colorGreen">
      //             {currencyFormatter(
      //               -tableData.getIn(["totalBeforeSolarBill"])
      //             )}
      //           </p>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen">
      //             After Solar - electricity bill (25 Yrs)
      //           </p>
      //           <p className="colorGreen">
      //             -
      //             {currencyFormatter(-tableData.getIn(["totalAfterSolarBill"]))}
      //           </p>
      //         </div>
      //       </div>

      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen">Additional savings in tax (25 Yrs)</p>
      //           <p className="colorGreen">
      //             +
      //             {currencyFormatter(tableData.getIn(["totalAdditionalTaxBenefit"]))}
      //           </p>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <h5 className="colorGreen">
      //             Total savings from Solar (25 Yrs)
      //           </h5>
      //           <h5 className="colorGreen boldFont">
      //             {currencyFormatter(tableData.getIn(["totalBillSaving"]))}
      //           </h5>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb mt-10">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen">Upfront payment</p>
      //           <p className="colorRed">
      //             -{currencyFormatter(-tableData.getIn(["upfrontPayment"]))}
      //           </p>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen colorGreen">Loan repayment</p>
      //           <p className="colorRed">
      //             -{currencyFormatter(-tableData.getIn(["totalLoanRepayment"]))}
      //           </p>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen">Operational expense, AMC (25 Yrs)</p>
      //           <p className="colorRed">
      //             -
      //             {currencyFormatter(-tableData.getIn(["totalOperationalExpenses"]))}
      //           </p>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <h5 className="colorGreen">Total ownership expense (25 Yrs)</h5>
      //           <h5 className="colorRed boldFont">
      //             -{currencyFormatter(-tableData.getIn(["totalOwnershipCost"]))}
      //           </h5>
      //         </div>
      //       </div>
      //     </>
      //   ) : null}
      //   <div className="mt-05 df fdr alic jcsb">
      //     <div className="df fdr jcsb w-95">
      //       <h5 className="colorGreen">Nett savings from Solar (25 Yrs)</h5>
      //       <h5 className="colorGreen boldFont">
      //         {currencyFormatter(tableData.getIn(["totalNetSavingFromSolar"]))}
      //       </h5>
      //     </div>
      //     <i
      //       onClick={() => {
      //         setCarousel({
      //           ...carousel,
      //           [isWithLoad ? "wl1" : "wor1"]:
      //             !carousel?.[isWithLoad ? "wl1" : "wor1"],
      //         });
      //       }}
      //       class={`upDownIcons fa fa-solid ${
      //         carousel?.[isWithLoad ? "wl1" : "wor1"]
      //           ? "fa-chevron-down"
      //           : "fa-chevron-up"
      //       } colorGreen boldFont`}
      //       style={{ fontSize: "1.4rem", cursor: "pointer" }}
      //     ></i>
      //   </div>
      //   <div className="mt-05 df fdr alic jcsb">
      //     <div className="df fdr jcsb w-95">
      //       <h5 className="colorGreen">ROI</h5>
      //       <h5 className="colorGreen boldFont">
      //         {(tableData.getIn(["averageRoi"]) * 100)?.toFixed(0)}%
      //       </h5>
      //     </div>
      //   </div>
      // </div>
    );
  };

  const renderStep3 = () => (
    <div className="df fdc w-100 mt-30" id={`dynamicId-${2}`}>
      {initLoader ? (
        <div className="df fdr alic jcc w-100 containerDiv">
          <i
            className="fa fa-spinner fa-spin mt-10 asc df"
            style={{
              fontSize: "30px",
              color: "var(--themeGreen)",
              alignSelf: "center",
            }}
          ></i>
        </div>
      ) : (
        <div>
          <h4 className="lightFont">
            Select the best suited package according to your needs
          </h4>
          <div className="df fdr alic w-100 mt-10">
            <div
              onClick={() => setModuleType(1)}
              className={`w-35 df fdc ${
                moduleType == 1 ? "selectedCard" : "unselectedCard"
              }`}
            >
              <div className="df m-10  fdr jcsb">
                <div className="df fdr">
                  <div className="radio mt-05">
                    <div
                      className={
                        moduleType == 1 ? "selectedRadio" : "unSelectedRadio"
                      }
                    ></div>
                  </div>
                  <div className="ml-10">
                    <h4 className="colorBlue boldFont">Premium</h4>
                    <h6 className="colorGreen lightFont">N-TOPCon modules</h6>
                  </div>
                </div>
                <div className="highlightedBg">
                  <span className="colorGreen">RECOMMENDED</span>
                </div>
              </div>
            </div>
            <div
              onClick={() => setModuleType(2)}
              className={`w-35 ml-20 df fdc ${
                moduleType == 2 ? "selectedCard" : "unselectedCard"
              }`}
            >
              <div className="df fdr m-10">
                <div className="radio mt-05">
                  <div
                    className={
                      moduleType == 2 ? "selectedRadio" : "unSelectedRadio"
                    }
                  ></div>
                </div>
                <div className="ml-10">
                  <h4 className="colorBlue boldFont">Standard</h4>
                  <h6 className="colorGreen lightFont">MONO PERC modules</h6>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="asc df fdc alic mt-10 jcsb w-45"> */}
          <div className="mt-20 df fdr w-45 alic jcsb">
            <h5 className="colorGreen mediumFont">
              Recommended Project Size (kW)
            </h5>
            <h5 className="colorGreen boldFont">
              {Number(userDetailsData.getIn(["0", "system_capacity"]))?.toFixed(
                2
              )}
            </h5>
          </div>
          <div className="mt-05 df fdr w-45 alic jcsb">
            <h5 className="colorGreen mediumFont">
              Monthly electricity bill reduced by
            </h5>
            <h5 className="colorGreen boldFont">
              {(
                (userFinancialsWithoutLoanData.getIn(["totalBillSaving"]) /
                  -userFinancialsWithoutLoanData.getIn([
                    "totalBeforeSolarBill",
                  ])) *
                100
              )?.toFixed(0)}
              %
            </h5>
          </div>
          <div className="mt-05 df fdr w-45 alic jcsb">
            <h5 className="colorGreen mediumFont">
              CO2 emissions reduced (Tons)
            </h5>
            <h5 className="colorGreen boldFont">
              {userFinancialsWithoutLoanData
                .getIn(["totalCo2Mitigation"])
                ?.toFixed(0)}
            </h5>
          </div>
          {/* </div> */}
          {renderTableData()}
          <div className="df fdr jcsb">
            <div className="w-30 df fdc mt-10">
              {/* {renderTableData(userFinancialsWithoutLoanData, false)} */}
              {renderChart(chartWithoutLoandata, "Full Upfront Payment")}
            </div>
            {/* <div className="w-30 df fdc mt-10"> */}
            {/* {renderTableData(userFinancialsWithLoanData, true)} */}
            {/* {renderChart(chartWithLoandata, "With Loan")} */}
            {/* </div> */}
            <div className="w-30 df fdc mt-10">
              {/* {renderTableData(userFinancialsWithoutLoanData, false)} */}
              {renderChart(chartRescodata, "No Upfront Payment")}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const monthsFormatter = (months) => {
    let years = Math.floor(months / 12)?.toFixed(0);
    let month = months - Number(years) * 12;
    return `${checkPlaceholderValue(years)}${
      years ? " Year(s) & " : ""
    }${checkPlaceholderValue(month)}${month ? " Month(s)" : ""}`;
  };

  return (
    <div className="centerAlignedDiv">
      <div className="dashboardContainer">
        <div className="dashboardHeadConatiner">
          <div className="dashboardHeadLeft">
            <h5 className="colorWhite lightBoldFont">
              Hey {props?.authData?.email?.split("@")?.[0]},
            </h5>
            <h1 className="colorWhite mediumBoldFont">
              You can earn upto{" "}
              <b>
                {checkPlaceholderValue(
                  (
                    userFinancialsWithoutLoanData.getIn([
                      "totalNetSavingFromSolar",
                    ]) / 100
                  )?.toFixed(2)
                )}{" "}
                Crores
              </b>{" "}
              from rooftop solar and the payback is only{" "}
              <b>
                {monthsFormatter(
                  userFinancialsWithoutLoanData.getIn(["paybackPeriod"])
                )}
              </b>
            </h1>
          </div>
          <div className="dashboardHeadRight">
            <h4 className="colorWhite lightBoldFont">
              We recommend
              <b>
                {" "}
                {checkPlaceholderValue(
                  Number(
                    userDetailsData.getIn(["0", "system_capacity"])
                  )?.toFixed(0)
                )}
                kW{" "}
              </b>
              rooftop solar. This will reduce upto yy% in your electricity bill
              and earn{" "}
              <b>
                {checkPlaceholderValue(
                  userFinancialsWithoutLoanData
                    .getIn(["totalCo2Mitigation"])
                    ?.toFixed(0)
                )}{" "}
                Tons
              </b>{" "}
              CO2 credits
            </h4>
          </div>
        </div>
        <div className="moduleConatiner">
          <h5 className="colorGreen tac">
            Select the best suited package according to your needs
          </h5>
          <div className="modulesWrapper">
            <div
              onClick={() => setModuleType(1)}
              className={`w-48 df fdc ${
                moduleType == 1 ? "selectedCard" : "unselectedCard"
              }`}
            >
              <div className="df m-05  fdr jcsb">
                <div className="df fdr">
                  <div className="radio mt-05">
                    <div
                      className={
                        moduleType == 1 ? "selectedRadio" : "unSelectedRadio"
                      }
                    ></div>
                  </div>
                  <div className="ml-10">
                    <h5 className="colorGreen semiBoldFont">Premium</h5>
                    <h6 className="colorGreyText lightFont">
                      N-TOPCon modules
                    </h6>
                  </div>
                </div>
                <div className="highlightedBg">
                  <span className="colorGreen">RECOMMENDED</span>
                </div>
              </div>
            </div>
            <div
              onClick={() => setModuleType(2)}
              className={`w-48 ml-20 df fdc ${
                moduleType == 2 ? "selectedCard" : "unselectedCard"
              }`}
            >
              <div className="df fdr m-05">
                <div className="radio mt-05">
                  <div
                    className={
                      moduleType == 2 ? "selectedRadio" : "unSelectedRadio"
                    }
                  ></div>
                </div>
                <div className="ml-10">
                  <h5 className="colorGreen semiBoldFont">Standard</h5>
                  <h6 className="colorGreyText lightFont">MONO PERC modules</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {![
        // userFinancialsWithLoanStatus,
        userFinancialsWithoutLoanStatus,
        userFinancialsRescoStatus,
      ].includes(STATE_STATUS.FETCHED) ? (
        <div className="df alic jcc mt-20">
          <i
            className="fa fa-spinner fa-spin colorGreen"
            style={{ fontSize: "24px" }}
          ></i>
        </div>
      ) : (
        <div className="dashboardPlansContainer">
          <div className="plansConatiner">
            <div
              className={
                `planItem` +
                (selectedPlan == PAYMENT_MODES.UPFRONT
                  ? " planItemSelected"
                  : "")
              }
            >
              <h3 className="colorDark mt-10 tac semiBoldFont">Full Upfront</h3>
              <h3 className="colorGreen mt-05 tac semiBoldFont">
                {TABLE_DATA?.getIn(["upfrontPayment", "upfront"])(
                  userFinancialsWithoutLoanData
                )}
              </h3>
              <div className="w-100 bgGrey  mt-20 mb-10">
                <p className="colorDark tac mediumFont pt-02 pb-02">
                  For 25 Years
                </p>
              </div>
              <div className="planDetailConatiner">
                <div className="df dfr alic">
                  <img
                    src={require("../../assets/img/check.png")}
                    className="w-1"
                  />
                  <p className="ml-5">
                    {TABLE_DATA?.getIn(["nettSaving", "title"])}
                  </p>
                </div>
                <p>
                  {TABLE_DATA?.getIn(["nettSaving", "upfront"])(
                    userFinancialsWithoutLoanData
                  )}
                </p>
              </div>
              <div className="planDetailConatiner">
                <div className="df dfr alic">
                  <img
                    src={require("../../assets/img/check.png")}
                    className="w-1"
                  />
                  <p className="ml-5">{TABLE_DATA?.getIn(["roi", "title"])}</p>
                </div>
                <p>
                  {TABLE_DATA?.getIn(["roi", "upfront"])(
                    userFinancialsWithoutLoanData
                  )}
                </p>
              </div>
              <div className="planDetailConatiner">
                <div className="df dfr alic">
                  <img
                    src={require("../../assets/img/check.png")}
                    className="w-1"
                  />
                  <p className="ml-5">
                    {TABLE_DATA?.getIn(["totalBillSaving", "title"])}
                  </p>
                </div>
                <p>
                  {TABLE_DATA?.getIn(["totalBillSaving", "upfront"])(
                    userFinancialsWithoutLoanData
                  )}
                </p>
              </div>
              {showMore ? (
                <>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn(["totalBeforeSolarBill", "title"])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn(["totalBeforeSolarBill", "upfront"])(
                        userFinancialsWithoutLoanData
                      )}
                    </p>
                  </div>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn(["totalAfterSolarBill", "title"])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn(["totalAfterSolarBill", "upfront"])(
                        userFinancialsWithoutLoanData
                      )}
                    </p>
                  </div>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn([
                          "totalAdditionalTaxBenefit",
                          "title",
                        ])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn([
                        "totalAdditionalTaxBenefit",
                        "upfront",
                      ])(userFinancialsWithoutLoanData)}
                    </p>
                  </div>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn(["totalOwnershipCost", "title"])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn(["totalOwnershipCost", "upfront"])(
                        userFinancialsWithoutLoanData
                      )}
                    </p>
                  </div>
                </>
              ) : null}
              {showMore ? (
                <div className="df fdrr p-10">
                  <p
                    onClick={() => setShowMore(false)}
                    className="colorGreen cp tdul"
                  >
                    less..
                  </p>
                </div>
              ) : (
                <div className="df fdrr p-10">
                  <p
                    onClick={() => setShowMore(true)}
                    className="colorGreen cp tdul"
                  >
                    more..
                  </p>
                </div>
              )}
              <div className="chartConatiner">
                {renderChart(chartWithoutLoandata, "Full Upfront Payment")}
              </div>
              <button
                disabled={selectedPlan == PAYMENT_MODES.UPFRONT}
                onClick={() => setSelectedPlan(PAYMENT_MODES.UPFRONT)}
                className={
                  `customCta w-90 asc mt-20` +
                  (selectedPlan == PAYMENT_MODES.UPFRONT
                    ? " bgDarkGrey"
                    : " bgGreen")
                }
              >
                <p className="colorWhite mediumFont">
                  {selectedPlan == PAYMENT_MODES.UPFRONT
                    ? "Selected Plan"
                    : "Select Plan"}
                </p>
              </button>
            </div>
            {/* <div
              className={
                `planItem` +
                (selectedPlan == PAYMENT_MODES.LOAN ? " planItemSelected" : "")
              }
            >
              <h3 className="colorDark mt-10 tac semiBoldFont">Loan</h3>
              <h3 className="colorGreen mt-05 tac semiBoldFont">
                {TABLE_DATA?.getIn(["upfrontPayment", "loan"])(
                  userFinancialsWithLoanData
                )}
              </h3>
              <div className="w-100 bgGrey  mt-20 mb-10">
                <p className="colorDark tac mediumFont pt-02 pb-02">
                  For 25 Years
                </p>
              </div>
              <div className="planDetailConatiner">
                <div className="df dfr alic">
                  <img
                    src={require("../../assets/img/check.png")}
                    className="w-1"
                  />
                  <p className="ml-5">
                    {TABLE_DATA?.getIn(["nettSaving", "title"])}
                  </p>
                </div>
                <p>
                  {TABLE_DATA?.getIn(["nettSaving", "loan"])(
                    userFinancialsWithLoanData
                  )}
                </p>
              </div>
              <div className="planDetailConatiner">
                <div className="df dfr alic">
                  <img
                    src={require("../../assets/img/check.png")}
                    className="w-1"
                  />
                  <p className="ml-5">{TABLE_DATA?.getIn(["roi", "title"])}</p>
                </div>
                <p>
                  {TABLE_DATA?.getIn(["roi", "loan"])(
                    userFinancialsWithLoanData
                  )}
                </p>
              </div>
              <div className="planDetailConatiner">
                <div className="df dfr alic">
                  <img
                    src={require("../../assets/img/check.png")}
                    className="w-1"
                  />
                  <p className="ml-5">
                    {TABLE_DATA?.getIn(["totalBillSaving", "title"])}
                  </p>
                </div>
                <p>
                  {TABLE_DATA?.getIn(["totalBillSaving", "loan"])(
                    userFinancialsWithLoanData
                  )}
                </p>
              </div>
              {showMore ? (
                <>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn(["totalBeforeSolarBill", "title"])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn(["totalBeforeSolarBill", "loan"])(
                        userFinancialsWithLoanData
                      )}
                    </p>
                  </div>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn(["totalAfterSolarBill", "title"])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn(["totalAfterSolarBill", "loan"])(
                        userFinancialsWithLoanData
                      )}
                    </p>
                  </div>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn([
                          "totalAdditionalTaxBenefit",
                          "title",
                        ])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn(["totalAdditionalTaxBenefit", "loan"])(
                        userFinancialsWithLoanData
                      )}
                    </p>
                  </div>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn(["totalOwnershipCost", "title"])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn(["totalOwnershipCost", "loan"])(
                        userFinancialsWithLoanData
                      )}
                    </p>
                  </div>
                </>
              ) : null}
              {showMore ? (
                <div className="df fdrr p-10">
                  <p
                    onClick={() => setShowMore(false)}
                    className="colorGreen cp tdul"
                  >
                    less..
                  </p>
                </div>
              ) : (
                <div className="df fdrr p-10">
                  <p
                    onClick={() => setShowMore(true)}
                    className="colorGreen cp tdul"
                  >
                    more..
                  </p>
                </div>
              )}
              <div className="chartConatiner">
                {renderChart(chartWithLoandata, "With Loan")}
              </div>
              <button
                disabled={selectedPlan == PAYMENT_MODES.LOAN}
                onClick={() => setSelectedPlan(PAYMENT_MODES.LOAN)}
                className={
                  `customCta w-90 asc mt-20` +
                  (selectedPlan == PAYMENT_MODES.LOAN
                    ? " bgDarkGrey"
                    : " bgGreen")
                }
              >
                <p className="colorWhite mediumFont">
                  {selectedPlan == PAYMENT_MODES.LOAN
                    ? "Selected Plan"
                    : "Select Plan"}
                </p>
              </button>
            </div> */}
            <div
              className={
                `planItem` +
                (selectedPlan == PAYMENT_MODES.RESCO ? " planItemSelected" : "")
              }
            >
              <h3 className="colorDark mt-10 tac semiBoldFont">RESCO</h3>
              <h3 className="colorGreen mt-05 tac semiBoldFont">
                {TABLE_DATA?.getIn(["upfrontPayment", "noUpfront"])(
                  userFinancialsRescoData
                )}
              </h3>
              <div className="w-100 bgGrey  mt-20 mb-10">
                <p className="colorDark tac mediumFont pt-02 pb-02">
                  For 25 Years
                </p>
              </div>
              <div className="planDetailConatiner">
                <div className="df dfr alic">
                  <img
                    src={require("../../assets/img/check.png")}
                    className="w-1"
                  />
                  <p className="ml-5">
                    {TABLE_DATA?.getIn(["nettSaving", "title"])}
                  </p>
                </div>
                <p>
                  {TABLE_DATA?.getIn(["nettSaving", "noUpfront"])(
                    userFinancialsRescoData
                  )}
                </p>
              </div>
              <div className="planDetailConatiner">
                <div className="df dfr alic">
                  <img
                    src={require("../../assets/img/check.png")}
                    className="w-1"
                  />
                  <p className="ml-5">{TABLE_DATA?.getIn(["roi", "title"])}</p>
                </div>
                <p>
                  {TABLE_DATA?.getIn(["roi", "noUpfront"])(
                    userFinancialsRescoData
                  )}
                </p>
              </div>
              <div className="planDetailConatiner">
                <div className="df dfr alic">
                  <img
                    src={require("../../assets/img/check.png")}
                    className="w-1"
                  />
                  <p className="ml-5">
                    {TABLE_DATA?.getIn(["totalBillSaving", "title"])}
                  </p>
                </div>
                <p>
                  {TABLE_DATA?.getIn(["totalBillSaving", "noUpfront"])(
                    userFinancialsRescoData
                  )}
                </p>
              </div>
              {showMore ? (
                <>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn(["totalBeforeSolarBill", "title"])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn(["totalBeforeSolarBill", "noUpfront"])(
                        userFinancialsRescoData
                      )}
                    </p>
                  </div>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn(["totalAfterSolarBill", "title"])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn(["totalAfterSolarBill", "noUpfront"])(
                        userFinancialsRescoData
                      )}
                    </p>
                  </div>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn([
                          "totalAdditionalTaxBenefit",
                          "title",
                        ])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn([
                        "totalAdditionalTaxBenefit",
                        "noUpfront",
                      ])(userFinancialsRescoData)}
                    </p>
                  </div>
                  <div className="planDetailConatiner">
                    <div className="df dfr alic">
                      <img
                        src={require("../../assets/img/check.png")}
                        className="w-1"
                      />
                      <p className="ml-5">
                        {TABLE_DATA?.getIn(["totalOwnershipCost", "title"])}
                      </p>
                    </div>
                    <p>
                      {TABLE_DATA?.getIn(["totalOwnershipCost", "noUpfront"])(
                        userFinancialsRescoData
                      )}
                    </p>
                  </div>
                </>
              ) : null}
              {showMore ? (
                <div className="df fdrr p-10">
                  <p
                    onClick={() => setShowMore(false)}
                    className="colorGreen cp tdul"
                  >
                    less..
                  </p>
                </div>
              ) : (
                <div className="df fdrr p-10">
                  <p
                    onClick={() => setShowMore(true)}
                    className="colorGreen cp tdul"
                  >
                    more..
                  </p>
                </div>
              )}
              <div className="chartConatiner">
                {renderChart(chartRescodata, "No Upfront Payment")}
              </div>
              <button
                disabled={selectedPlan == PAYMENT_MODES.RESCO}
                onClick={() => setSelectedPlan(PAYMENT_MODES.RESCO)}
                className={
                  `customCta w-90 asc mt-20` +
                  (selectedPlan == PAYMENT_MODES.RESCO
                    ? " bgDarkGrey"
                    : " bgGreen")
                }
              >
                <p className="colorWhite mediumFont">
                  {selectedPlan == PAYMENT_MODES.RESCO
                    ? "Selected Plan"
                    : "Select Plan"}
                </p>
              </button>
            </div>
          </div>
          <button
            onClick={() => {
              navigate("/onboarding", {
                state: {
                  id: userDetailsData?.getIn([0, "id"]),
                },
              });
            }}
            className={"customCta mt-20 recalculate"}
          >
            <span className="reloadIcon">&#x21bb;</span>
            <p className="colorGreen mediumFont ml-5">Recalculate</p>
          </button>
          {selectedPlan ? (
            <div className="checkoutContainer" ref={checkoutRef}>
              <h4 className="tac colorGreen">Proceed to e-bidding</h4>
              <h6 className="tac colorGreyText ">
                Our installers will compete for your project and we will provide
                you quotations and comparison within next 2 days.
              </h6>
              <div className="df fdr alic jcsb w-100 mt-10">
                <h5>Selected Plan</h5>
                <h5>{selectedPlan}</h5>
              </div>
              <div className="df fdr alic jcsb w-100 mt-10">
                <h5>
                  Refundable Deposit{" "}
                  <span className="colorGreyText mediumFont">(incl GST)</span>
                </h5>
                <h5>₹199</h5>
              </div>
              <div className="mt-10">
                <p className="colorGreyText taj">
                  By paying, you agree to the terms of how the deposit may be
                  adjusted, refunded, or forfeited as outlined below.
                </p>
              </div>
              <div className="df fdr alic mt-10">
                <div
                  onClick={() => setIsAcceptance(!isAcceptance)}
                  className="checkbox"
                >
                  {isAcceptance ? (
                    <span className="colorGreen">&#10004;</span>
                  ) : null}
                </div>
                <p className="colorGreyText ml-5 taj">
                  I accept the{" "}
                  <a
                    target="_blank"
                    href="https://www.resixty.com/termsofuse.html"
                    className="colorGreen tdul"
                  >
                    Terms of Use
                  </a>{" "}
                  &{" "}
                  <a
                    target="_blank"
                    href="https://www.resixty.com/refundpolicy.html"
                    className="colorGreen tdul"
                  >
                    Refund Policy
                  </a>
                  {/* This is a one time refundable deposit that you have to pay in
                order to intiate the bidding process. The deposit is refundable
                for more details you can go through our{" "}
                <a className="colorGreen tdul">Refund Policy</a>. The bidding
                process will be initiated as soon as you make a successful
                payment. After the payment goes through our Installers will
                place their bid and Resixty will help in ensuring that you get
                the best bid for your project. */}
                </p>
              </div>
              <button
                disabled={!isAcceptance}
                className={
                  "mt-20 customCta" +
                  (!isAcceptance ? " bgDarkGrey" : " bgGreen")
                }
              >
                <p className="colorWhite">PAY ₹199</p>
              </button>
            </div>
          ) : null}
          {/* <div className="mt-20 mb-20 ml-20 mr-20">
            <h6 className="colorGreyText lightBoldFont">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              tincidunt purus ut magna pharetra, nec varius metus venenatis.
              Pellentesque habitant morbi tristique senectus et netus et
              malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in
              faucibus orci luctus et ultrices posuere cubilia curae; Phasellus
              suscipit sem in condimentum accumsan. Vivamus euismod augue sed
              sapien varius iaculis.
              <br />
              <br />
              Donec dignissim sed felis et faucibus. Ut nec gravida nunc. Sed
              non metus pharetra, hendrerit sapien vel, cursus enim. In luctus,
              massa a congue euismod, ex purus pellentesque ipsum, id
              scelerisque elit nisl ac est. Phasellus mollis dui vel neque
              vestibulum elementum. In fringilla vestibulum tempor.
              <br />
              <br />
              Mauris vel nunc ac tortor bibendum facilisis. Fusce a mi urna.
              Cras eu purus eu justo consectetur ullamcorper. Proin quis turpis
              at justo pharetra ultricies. Donec viverra turpis erat. Etiam sit
              amet mi sit amet est varius malesuada. Nulla eget cursus nibh, non
              ultrices elit. Donec iaculis, risus malesuada pharetra porttitor,
              tellus turpis maximus metus, rhoncus blandit velit urna eget sem.
              <br />
              <br />
              Fusce non turpis sit amet turpis molestie ultrices. Aenean congue
              ex eget orci dignissim, at porta metus aliquam. Fusce quis rhoncus
              elit. Praesent sed convallis nisi. Donec viverra a elit vulputate
              mollis. Pellentesque est sem, eleifend dictum lorem id, porttitor
              vehicula neque. Vestibulum consectetur risus eget nulla dapibus,
              sed porta tellus blandit. Praesent vestibulum sit amet nisl ut
              elementum.
              <br />
              <br />
              Aliquam id dui ipsum. Mauris dui lectus, vulputate a ornare in,
              gravida vitae enim. Donec sed eros tortor. Mauris ex purus,
              rhoncus in placerat vitae, commodo eget dolor. Aliquam blandit
              tempor euismod. Sed eget felis euismod, facilisis magna ut,
              aliquet metus. Vestibulum fermentum augue eros, non venenatis
              justo ultricies at. Phasellus pharetra eget ligula sit amet
              vestibulum.
            </h6>
          </div> */}
        </div>
      )}
      {/* <div className="widthAdjuster card">{renderStep3()}</div> */}
    </div>
  );
};

export default Output;
