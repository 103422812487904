import React, { useEffect, useState } from "react";
import "./index.css";
// import { GoogleLogin } from "@react-oauth/google";
import { login, signup, verifyEmail } from "../../services/auth";
// import {
//   getDownloadURL,
//   ref as storageRef,
//   uploadBytes,
// } from "firebase/storage";

const Auth = (props) => {
  const isSupplier = window.location.href.includes("isSupplier=true");
  const isInstaller = window.location.href.includes("isInstaller=true");
  const paramsData = window.location.href
    ?.split("?")?.[1]
    ?.split("&")
    ?.map((_) => ({ key: _?.split("=")?.[0], value: _?.split("=")?.[1] }));
  const source = paramsData?.find((_) => _?.key == "utm_source")?.value;
  const medium = paramsData?.find((_) => _?.key == "utm_medium")?.value;
  const campaign = paramsData?.find((_) => _?.key == "utm_campaign")?.value;
  const channel = paramsData?.find((_) => _?.key == "utm_channel")?.value;

  const { setIsAuthenticated, setToken, setAuthData } = props;

  const FLOWS = {
    0: {
      title: `Login`,
      index: 0,
      updateState: 1,
      hyperLinkText: "Don't have an account? Sign Up",
      ctaText: "Login",
    },
    1: {
      title: `Sign Up`,
      index: 1,
      updateState: 0,
      hyperLinkText: "Already have an account? Login",
      ctaText: "Sign Up",
    },
    2: {
      title: `Forgot Password`,
      index: 2,
      updateState: 0,
      hyperLinkText: "Already have an account? Login",
      ctaText: "Verify",
    },
  };
  const [flow, setFlow] = useState(0);
  // const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState(null);

  const onNext = async (e) => {
    try {
      e.preventDefault();
      let authObj = flow
        ? {
            user_type: isInstaller ? 2 : isSupplier ? 1 : 3,
            channel: `${source} | ${medium} | ${campaign} | ${channel}`,
            email,
            phone: number,
            password,
          }
        : {
            email,
            phone: number,
            password,
          };
      setLoading(true);
      const { data } =
        flow == 1
          ? await signup(authObj)
          : flow == 0
          ? await login(authObj)
          : await verifyEmail({
              email,
            });
      if (data?.success) {
        if (data?.data?.token) {
          setAuthData(data?.data);
          setToken(data?.data?.token);
          setIsAuthenticated(true);
        } else {
          alert(data.message);
          setLoading(false);
          setFlow(0);
        }
      } else {
        alert(data?.message);
        setLoading(false);
      }
      return;
    } catch (e) {
      alert(e?.response?.data?.message);
      setLoading(false);
      return;
    }
  };

  return (
    <div className="container">
      <div className="card">
        <div className="fieldsContainer">
          <div className="df fdc alic">
            <img
              src={require("../../assets/logo.png")}
              alt="logo"
              className="formLogo"
            />
            <h6 className="mt-10 colorGrexTextColor boldFont">
              {isSupplier || isInstaller ? "Partner" : ""}{" "}
              {FLOWS?.[flow]?.title}
            </h6>
            <h6
              className="cp tdul colorGrexTextColor mediumFont"
              onClick={() => setFlow(FLOWS?.[flow]?.updateState)}
            >
              {FLOWS?.[flow]?.hyperLinkText}
            </h6>
          </div>
          <form onSubmit={onNext} className={"df fdc mt-10"}>
            <div className={"fieldItem"}>
              {flow == 1 ? (
                <>
                  <label className="colorGrexTextColor " htmlFor="number">
                    Mobile Number
                  </label>
                  <input
                    className="mt-5"
                    minLength={10}
                    value={number}
                    onChange={(e) => {
                      if (!isNaN(e?.target?.value)) {
                        setNumber(e?.target?.value);
                      }
                    }}
                    type="tel"
                    id="number"
                    name="number"
                    required={flow == 1}
                    maxLength={10}
                    placeholder="9988****55"
                  />
                </>
              ) : null}
            </div>
            <div className="fieldItem">
              <label className="mt-10 colorGrexTextColor " htmlFor="email">
                Email
              </label>
              <input
                className="mt-5"
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
                type="email"
                required
                minLength={5}
                id="email"
                name="email"
                placeholder="JohnDoe@mail.com"
              />
            </div>
            {[0, 1].includes(flow) ? (
              <div className="fieldItem">
                <label className="mt-10 colorGrexTextColor " htmlFor="password">
                  Password
                </label>
                <input
                  className="mt-5"
                  value={password}
                  required
                  minLength={8}
                  onChange={(e) => setPassword(e?.target?.value)}
                  type="password"
                  id="password"
                  name="password"
                  placeholder="*********"
                />
              </div>
            ) : null}
            <div className="mt-10">
              <p
                onClick={() => setFlow([0, 1].includes(flow) ? 2 : 0)}
                className="tac tdul cp colorGrexTextColor"
              >
                {flow == 2 ? "" : "Forgot Password?"}
              </p>
            </div>
            <button
              type="submit"
              className="customCta authBtn"
              disabled={loading}
            >
              <span className="mediumFont colorDark">
                {FLOWS?.[flow]?.ctaText}
              </span>
              {loading ? (
                <i
                  className="fa fa-spinner fa-spin colorWhite ml-10"
                  style={{ fontSize: "16px" }}
                ></i>
              ) : null}
            </button>
          </form>
          {/* {isSupplier || isInstaller ? null : (
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                console.log(credentialResponse);
              }}
              ux_mode={"popup"}
              scope={
                "email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
              }
              shape={"rectangular"}
              theme={"filled_black"}
              logo_alignment={"left"}
              login_uri={"http://localhost:3000"}
              onError={() => {
                console.log("Login Failed");
              }}
              useOneTap
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Auth;
